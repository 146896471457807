import { ChainPart, ChainPartPriority, ChainPartResult, createChainPart } from "./chainParts.ts";
import { voucherCodeFromScannedCode } from "../../../global/utils/vouchers/Voucher.ts";
import { toast } from "react-toastify";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import useVouchers from "../../../global/utils/useVouchers.ts";
import { useMemo } from "react";

export function useJamezzVoucherChainPart(): ChainPart {
  const checkVoucherCode = useVouchers({ withLoading: false, withToasts: false });

  return useMemo(() => {
    return createChainPart(ChainPartPriority.Medium, (data: string) => {
      return new Promise<ChainPartResult>((resolve) => {
        if (data.length >= 3 && data.substring(0, 3) === "QRD") {
          const code = voucherCodeFromScannedCode(data);
          if (code) {
            checkVoucherCode(code, 1 /* 1 means that we scan 1 voucher at a time */)
              .then(() => {
                toast.success(<FormattedMessageJamezz id="Voucher.messages.added-successfully" />);
                resolve("found");
              })
              .catch(() => {
                resolve("not_found");
              });
          } else {
            resolve("not_found");
          }
        } else {
          resolve("not_found");
        }
      });
    });
  }, [checkVoucherCode]);
}
