import Box from "@mui/material/Box";
import { Stack, SwipeableDrawer } from "@mui/material";

import { useFetchLoyaltyProgramQuery } from "../../../global/utils/redux/api/piggyApi";
import store, { useAppSelector } from "../../../global/utils/redux/store.tsx";
import {
  piggyContactIdentifierDialogClosed,
  piggyContactIdentifierDialogOpened,
} from "../../../global/utils/redux/piggySlice.tsx";
import { LoyaltyCard } from "./Loyalty/LoyaltyCard/LoyaltyCard.tsx";
import PiggyVouchers from "./Vouchers/PiggyVouchers.tsx";
import { useEffect } from "react";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import PiggyRewards from "./Loyalty/PiggyRewards.tsx";

export default function PiggyDialog() {
  const isLoggedIn = useAppSelector((state) => state.piggy.contactIdentifierLoggedIn);
  const isOpen = !useAppSelector((state) => state.piggy.piggyContactIdentifierDialogMinimized);
  const { data: loyaltyProgram, isSuccess: loyaltySuccess } = useFetchLoyaltyProgramQuery(undefined, { skip: !isOpen });
  const { getDialogsCount } = useDialog();

  const quickLoyaltyButtonEnabled = useAppSelector(
    (state) =>
      (state.global.salesarea.piggy.loyalty.enabled && state.global.salesarea.piggy.loyalty.show_loyalty_in_app) ||
      state.global.salesarea.piggy.prepaid.enabled
  );

  useEffect(() => {
    if (isLoggedIn && (getDialogsCount() > 0 || !quickLoyaltyButtonEnabled)) {
      store.dispatch(piggyContactIdentifierDialogOpened());
    }
  }, [getDialogsCount, isLoggedIn, quickLoyaltyButtonEnabled]);

  return (
    <SwipeableDrawer
      ModalProps={{
        keepMounted: false,
      }}
      anchor={"bottom"}
      open={isOpen}
      sx={{ zIndex: 1300, "> .MuiPaper-root": { borderTopLeftRadius: 48, borderTopRightRadius: 48 } }}
      onClose={() => store.dispatch(piggyContactIdentifierDialogClosed())}
      onOpen={() => store.dispatch(piggyContactIdentifierDialogOpened())}
    >
      {isOpen && (
        <Box
          className="JS-Piggy-RewardsPage"
          data-cy="piggy-dialog-rewards"
          sx={{
            width: 1,
            maxHeight: 1400,
            py: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              alignSelf: "center",
              width: 1,
              mt: 2,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Stack sx={{ width: 1, my: 4 }} alignItems={"center"} direction={"column"} gap={6}>
              <LoyaltyCard wrapperSx={{ width: 600 }} />

              <PiggyRewards showBalance={false} wrapperSx={{ maxWidth: 1 }} />

              <PiggyVouchers wrapperSx={{ maxWidth: 1 }} />
            </Stack>
          </Box>
        </Box>
      )}
    </SwipeableDrawer>
  );
}
