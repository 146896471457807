import { useCallback } from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz";
import CloseDialogButton from "./CloseDialogButton";
import { useAppDispatch, useAppSelector } from "../../global/utils/redux/store";
import { setProductAddedByBarcodeDialog } from "../../global/utils/redux/kioskSlice";
import { selectArticlesSum } from "../../global/utils/redux/shoppingCartSlice";

export default function ProductAddedByBarcodeDialog() {
  const isOpen = useAppSelector((state) => state.kiosk.productAddedByBarcodeDialogIsOpen);

  const dispatch = useAppDispatch();
  const count = useAppSelector(selectArticlesSum);

  const onClose = useCallback(() => {
    dispatch(setProductAddedByBarcodeDialog({ isOpen: false }));
  }, [dispatch]);

  return (
    <Dialog
      // TransitionComponent={TransitionComponent}
      fullScreen={false}
      fullWidth={true}
      maxWidth={"lg"}
      open={isOpen}
      onClose={onClose}
      // sx={{ zIndex: 1000 ?? undefined }}
      onBackdropClick={() => {
        dispatch(setProductAddedByBarcodeDialog({ isOpen: false }));
      }}
    >
      <CloseDialogButton
        onClose={() => {
          dispatch(setProductAddedByBarcodeDialog({ isOpen: false }));
        }}
      />
      <DialogContent sx={{ margin: 6 }} data-cy="scanner-added-item-dialog">
        <Typography>
          <FormattedMessageJamezz id={"{count} items in shopping cart!"} values={{ count }} />
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
