import store, { useAppSelector } from "../../redux/store";
import { useCallback } from "react";
import Article, { getArticleDescription, getArticleName } from "../../models/menu/Article";
import { getArticlegroupName } from "../../models/menu/Articlegroup";
import { selectMenuTagFilters } from "../../redux/selectors/selectMenuTagFilters";
import { selectArticleArticlegroupsMap } from "../../redux/selectors/selectArticleArticlegroupsMap";
import { selectArticlegroupsMap } from "../../redux/selectors/selectArticlegroupsMap";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import stringSimilarity from "string-similarity";
import { selectAppLanguage } from "../../../../kiosk/components/LanguageSelector/useLanguage.ts";
import { useIntl } from "react-intl";

export default function useArticleSearchFiltering() {
  const articleSearchValue = useAppSelector((state) => state.global.articleSearchText);
  const articleArticlegroupsMap = useAppSelector(selectArticleArticlegroupsMap);
  const lang = useAppSelector(selectAppLanguage);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const intl = useIntl();

  return useCallback(
    (articles: Article[]) => {
      const articleSearchValueLowerCase = articleSearchValue.toLowerCase();
      const menuTagFilters = selectMenuTagFilters(store.getState(), intl);
      if (!articleSearchValue) {
        return articles;
      } else {
        const results: { article: Article; score: number }[] = [];

        articles.forEach((article) => {
          let score = 0;
          const subjectName = (getArticleName(article, lang) ?? "").toLowerCase();

          if (articleSearchValueLowerCase.length === 1) {
            if (subjectName.charAt(0) === articleSearchValueLowerCase.charAt(0)) {
              score = 1;
            } else if (subjectName.includes(articleSearchValueLowerCase)) {
              score = 0.9;
            }
          } else {
            const subjectNames = subjectName.match(/\b(\w+)\b/g);
            const articleSearchValueLowerCases = articleSearchValueLowerCase.match(/\b(\w+)\b/g);

            subjectNames?.forEach((subjectName) => {
              articleSearchValueLowerCases?.forEach((subjectName2) => {
                score += compareStrings(subjectName2, subjectName.toLowerCase());
              });
            });
          }

          article.menuTagIds.forEach((menuTagId) => {
            if (score === 0 && menuTagFilters[menuTagId]?.name) {
              score += compareStrings(articleSearchValueLowerCase, menuTagFilters[menuTagId].name.toLowerCase());
            }
          });

          let allArticlegroupsDisabled = true;
          if (articleArticlegroupsMap[article.id]) {
            for (const articlegroupId of articleArticlegroupsMap[article.id]) {
              const articlegroup = articlegroupsMap[articlegroupId];

              if (articlegroup) {
                if (!articlegroup.disableOrdering && articlegroup.showInCategoryMenu) {
                  allArticlegroupsDisabled = false;
                }

                const articlegroupName = getArticlegroupName(articlegroup, lang);
                if (articlegroupName) {
                  score += compareStrings(articleSearchValueLowerCase, articlegroupName.toLowerCase());
                }
              }
            }
          }

          const articleDescription = getArticleDescription(article, lang);
          if (articleDescription) {
            score += compareStrings(articleSearchValueLowerCase, articleDescription.toLowerCase()) / 1.2;
          }

          if (score && !allArticlegroupsDisabled) {
            results.push({ article, score });
          }
        });

        return results
          .sort((a, b) => {
            return b.score - a.score;
          })
          .filter((a) => a.score > 0.5)
          .map((a) => a.article);
      }
    },
    [articleSearchValue, lang, articleArticlegroupsMap, articlegroupsMap, intl]
  );
}

function compareStrings(a: string, b: string): number {
  return b.includes(a) ? 1 : 0;
}
