import { useCallback, useEffect, useRef } from "react";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider.tsx";
import OrderArticle from "../../../../global/utils/models/order/OrderArticle.ts";
import OrderArticleSelectorPage from "../OrderArticleSelectorPage.tsx";

export default function useNestedOptionGroupsDialog() {
  const { openDialog, closeDialog } = useDialog();
  return useCallback(
    (orderArticle: OrderArticle): Promise<OrderArticle | undefined> => {
      return new Promise<OrderArticle | undefined>((resolve) => {
        if (orderArticle.orderOptionGroups.length > 0) {
          openDialog({
            sx: {
              ".MuiDialog-paper": {
                borderRadius: 12,
              },
            },
            disabledBackdropClick: true,
            children: (
              <NestedOptionGroupsDialog
                orderArticle={orderArticle}
                onClose={(orderArticle?: OrderArticle) => {
                  if (orderArticle) {
                    closeDialog();
                  }
                  resolve(orderArticle);
                }}
              />
            ),
          });
        } else {
          resolve(orderArticle);
        }
      });
    },
    [openDialog, closeDialog]
  );
}

function NestedOptionGroupsDialog({
  orderArticle,
  onClose,
}: {
  orderArticle: OrderArticle;
  onClose: (orderArticle?: OrderArticle) => void;
}) {
  const closedWithOrderArticle = useRef<OrderArticle>();
  useEffect(() => {
    return () => {
      if (closedWithOrderArticle.current == undefined) {
        onClose();
      }
    };
  }, [onClose]);
  return (
    <OrderArticleSelectorPage
      article={orderArticle.article}
      fixedCount={1}
      onOrderArticleOk={(orderArticle) => {
        closedWithOrderArticle.current = orderArticle;
        onClose(orderArticle);
      }}
    />
  );
}
