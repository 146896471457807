import SlideUpTransition from "../../../components/SlideUpTransition.tsx";
import { useCallback } from "react";
import { useDialog } from "../../../utils/dialog/DialogProvider.tsx";
import QrArticleSuggestionPage from "../../../../qr/pages/ArticleSuggestionPage/ArticleSuggestionPage.tsx";
import KioskArticleSuggestionPage from "../../../../kiosk/pages/ArticleSuggestionPage/ArticleSuggestionPage.tsx";
import Article from "../../../utils/models/menu/Article.ts";
import { getArticleById } from "../../../utils/models/menu/Menu.ts";
import useMenuFilters from "../../../utils/menu/filters/useMenuFilters.ts";
import useIsWidescreen from "../../../utils/useIsWidescreen.ts";
import store from "../../../utils/redux/store.tsx";
import { selectArticlesMap } from "../../../utils/redux/selectors/selectArticlesMap.ts";
import isKiosk from "../../../utils/helpers/isKiosk.tsx";
import { selectAppLanguage } from "../../../../kiosk/components/LanguageSelector/useLanguage.ts";
import ShowArticleDialogAction from "../../../utils/automation/actions/ShowArticleDialogAction.ts";

export default function useOpenUpsellPage() {
  const { openDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  const articleFilter = useMenuFilters();

  return useCallback(
    (action: ShowArticleDialogAction, source: string) => {
      const lang = selectAppLanguage(store.getState());
      if (store.getState().global.salesarea.custom_data.upsell_enabled === false) {
        return;
      }
      const ArticleSuggestionPage = isKiosk() ? KioskArticleSuggestionPage : QrArticleSuggestionPage;
      const articles = retrieveValidArticles(articleFilter, action.articleIds);
      if (articles.length > 0) {
        openDialog({
          children: (
            <ArticleSuggestionPage
              articles={articles}
              upsellType={action.upsellId ? "Upsells" : "Freeoption"}
              upsellId={action.upsellId}
              upsellSource={source ?? null}
              title={action.titleTranslations?.[lang] ?? action.title}
              text={action.descriptionTranslations?.[lang] ?? action.description}
              useAsDialog={true}
              showBottomHideButton={action.showBottomHideButton ?? true}
            />
          ),
          fullScreen: isKiosk() ? "almostFullScreen" : !isWidescreen,
          TransitionComponent: SlideUpTransition,
        });
      }
    },
    [openDialog, articleFilter, isWidescreen]
  );
}

export function retrieveValidArticles(
  articleFilter: (
    article: Article,
    index?: number,
    array?: Article[],
    usePreferences?: boolean,
    useArrangementsF?: boolean,
    useArticlegroupsF?: boolean
  ) => boolean,
  articleIds: string[]
) {
  let articles: Article[] = [];
  const articlesMap = selectArticlesMap(store.getState());
  articleIds.forEach((articleId) => {
    const article = getArticleById(articlesMap, articleId);
    if (article) {
      articles.push(article);
    }
  });
  articles = articles.filter(articleFilter);
  return articles.filter((article) => {
    return !(article.isOutOfStock || article.isNotAvailable);
  });
}
