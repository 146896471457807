import { JamezzApiResponse, RESPONSE_CODES } from "./api/common_types";
import { VoucherV2Response } from "../../global/utils/vouchersV2/api/VoucherResponses.ts";

export type ContactByIdentifierResponse = {
  data: {
    uuid: string;
    is_new: boolean;
    contact: {
      firstname: string | null;
      is_birthday: boolean | null;
      tier?: {
        value: string;
        attribute: {
          options: { value: string; label: string; description: string; media: { type: string; value: string } }[];
        };
      } | null;
    };
  };
};

export type PiggyResponseOk = {
  type: "success";
  message: string;
};

export type PiggyResponseBad = {
  status_code: RESPONSE_CODES.badRequest;
  message: string;
  body: {
    code: number;
    status_code: number;
    message: string;
  };
};
export type LoggedInUserResponse = {
  data: {
    has_session: boolean;
    piggy_contact_email: string | null;
    piggy_contact_uuid: string | null;
    contact: {
      firstname: string | null;
      is_birthday: boolean | null;
      tier?: {
        value: string;
        attribute: {
          options: { value: string; label: string; description: string; media: { type: string; value: string } }[];
        };
      } | null;
      tierV2?: {
        uuid: string;
        name: string;
        description: string;
      };
    };
  };
} & JamezzApiResponse;
export type SendContactEmailResponse = {
  data: {
    contact_email: string;
    piggy_response: PiggyResponseBad | PiggyResponseOk;
  };
} & JamezzApiResponse;
export type SendVerificationCodeResponse = {
  data:
    | {
        contact_email: string;
        piggy_response: PiggyResponseBad;
      }
    | {
        has_session: boolean;
        piggy_contact_email: string | null;
        piggy_contact_uuid: string | null;
      };
} & JamezzApiResponse;
export type CreateAcountResponse = {
  data: {
    uuid: string;
  } & {
    contact_email: string;
    piggy_response: PiggyResponseBad;
  };
} & JamezzApiResponse;

export type PointsBalanceResponse = {
  data: {
    balance: number;
  };
} & JamezzApiResponse;

export type ListVouchersResponse = {
  data: PiggyVoucher[];
} & JamezzApiResponse;

export type GiftcardResponse = {
  data: {
    giftcard: PiggyGiftcard;
  };
} & JamezzApiResponse;

export type PrepaidBalanceResponse = {
  data: {
    prepaid_balance: { balance: string; balance_in_cents: number };
  };
} & JamezzApiResponse;

export type PiggyGiftcard = {
  amount_in_cents: number;
  expiration_date: null;
  hash: string;
  is_active: boolean;
  is_upgradeable: boolean;
  type: number;
  uuid: string;
};
export type PiggyVoucher = {
  attributes: { [key: string]: any };
  code: string;
  contact: unknown;
  description: string;
  expiration_date?: string;
  activation_date?: string;
  is_redeemed: boolean;
  name: string;
  promotion: {
    uuid: string;
    name: string;
    expiration_date: unknown;
    description: string;
    limit_per_contact: number | undefined;
    voucher_limit: number;
    type: "MULTI_USE" | "SINGLE_USE";
  };
  redeemed_at: unknown;
  status: string;
  jamezzVoucher: VoucherV2Response;
  uuid: string;
  redemptions_remaining?: number | null;
  times_redeemed?: number | null;
  total_redemptions_allowed?: number | null;
  // jamezz_voucher_definition: VoucherDef;
};

export type PiggyVoucherStatus = "ACTIVE" | "DEACTIVATED";
export type PiggyReward = {
  id: number;
  media: {
    type: "image";
    value: string;
  } | null;
  title: string;
  description: string | null;
  reward_type: "PHYSICAL" | "DIGITAL";
  required_credits: number;
  // should not be null, but can be!
  artikelid: string | null;
  uuid: string;
};
export type RewardsResponse = {
  data: PiggyReward[];
} & JamezzApiResponse;
export type ClaimRewardResponse = {
  data: {
    contact_email?: string;
    piggy_response: PiggyResponseBad;
  } & {
    type: "reward_reception";
    credits: number;
    uuid: string;
    contact: {
      uuid: string;
    };
    shop: {
      name: string;
      uuid: string;
    };
    // only null?
    contact_identifier: null;
    created_at: string;
    title: string;
    reward: {
      reward_type: "PHYSICAL" | "DIGITAL";
      uuid: string;
      artikelid: string;
    };
    expires_at: null | string;
    has_been_collected: boolean;
  };
} & JamezzApiResponse;
export type LoyaltyProgram = {
  data: {
    custom_credit_name: string;
  };
} & JamezzApiResponse;
export type PiggyTransaction = {
  type: "credit_reception";
  credits: number;
  uuid: string;
  created_at: string;
};
export type TransactionHistoryResponse =
  | ({
      data: ReadonlyArray<PiggyTransaction>;
    } & JamezzApiResponse)
  | {
      message: string;
      errors: {
        [k: string]: string[];
      };
    };

export const piggyErrorCodes = {
  "insufficient credits": 1017,
  "out of stock": 1018,
  "claim limit reached": 1019,
  "contact not found": 55031,
  // piggy message="Passcode not found"
  "verification code incorrect": 13010,
} as const;

export type PiggyErrorResponse = {
  data: {
    context: {
      message: string;
      // this is the piggy error code
      code: (typeof piggyErrorCodes)[keyof typeof piggyErrorCodes];
      status_code: number;
    };
  };
};
