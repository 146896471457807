import { usePiggyUser } from "../../utils/piggy/piggy.tsx";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";

export default function PiggyPopupAtCheckoutPage() {
  const { isLoggedIn: piggyUserIsLoggedIn, piggyEnabled } = usePiggyUser();
  const showLoyalty = useAppSelector(
    (state) => state.global.salesarea.piggy.loyalty.enabled && state.global.salesarea.piggy.loyalty.show_loyalty_in_app
  );

  const intl = useIntl();
  useEffect(() => {
    if (piggyEnabled && !piggyUserIsLoggedIn && showLoyalty) {
      toast.warning(intl.formatMessage({ id: "Don't miss out on points, scan your loyalty card!" }));
    }
  }, [piggyEnabled, piggyUserIsLoggedIn, intl, showLoyalty]);
  return null;
}
