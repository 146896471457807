import { useCallback, useMemo, useState } from "react";
import OrderArticle, { orderArticlesAreEqual } from "../../../../global/utils/models/order/OrderArticle.ts";
import _ from "lodash";
import {
  ArrangementCheckResult,
  useArrangementChecks,
} from "../../../../global/components/ArrangementLimitations/ArrangementLimitations.tsx";
import { useAgeCheck } from "../../../../global/components/AgeCheckDialog.tsx";
import store from "../../../../global/utils/redux/store.tsx";
import { checkStockAndMaxCount } from "../../../../global/utils/order/useAddOrderArticleToShoppingCart.tsx";
import { AgeCheckResult } from "../../../../global/utils/ageCheck/ageCheckUtils.ts";

export default function useMiniShoppingCart({ maxCount, groupKey }: { maxCount: number; groupKey: string }) {
  const [orderArticles, setOrderArticles] = useState<Record<string, OrderArticle[]>>({});

  const totalCountPerGroup = useMemo(() => {
    return _.mapValues(orderArticles, (orderArticles) => {
      return _.chain(orderArticles).sumBy("count").value();
    });
  }, [orderArticles]);

  const checkArrangements = useArrangementChecks();
  const checkAge = useAgeCheck();

  const addOrderArticle = useCallback(
    (orderArticle: OrderArticle, options: { cleanShoppingCart?: boolean } = {}) => {
      return new Promise((resolve, reject) => {
        (async () => {
          const orderArticlesFromStore = store.getState().shoppingCart.items;
          const orderArticles2 = options.cleanShoppingCart ? [] : (orderArticles[groupKey] ?? []);

          if (!checkStockAndMaxCount(orderArticle, [...orderArticles2, ...orderArticlesFromStore])) {
            reject("stock_denied");
            return;
          }

          if ((options.cleanShoppingCart ? 0 : totalCountPerGroup[groupKey]) >= maxCount && orderArticle.count > 0) {
            reject("max_count_denied");
            return;
          }

          const arrangementRulesCheck = await checkArrangements(orderArticle);
          if (arrangementRulesCheck === ArrangementCheckResult.Accepted) {
            const ageCheckResult = await checkAge(orderArticle);
            if (ageCheckResult === AgeCheckResult.Denied) {
              reject("age_denied");
              return;
            }
          } else {
            reject("arrangement_check_denied");
            return;
          }

          const state = [...orderArticles2];
          const itemIndex = state.findIndex((item) => orderArticlesAreEqual(item, orderArticle));
          const item = state[itemIndex];

          if (item) {
            item.count += orderArticle.count;
            if (item.count === 0) {
              state.splice(itemIndex, 1);
            }
          } else {
            if (orderArticle.count > 0) {
              state.push(orderArticle);
            }
          }

          setOrderArticles({ ...orderArticles, [groupKey]: state });
          resolve("ok");
        })();
      });
    },
    [checkAge, checkArrangements, groupKey, maxCount, orderArticles, totalCountPerGroup]
  );
  if (totalCountPerGroup[groupKey] === undefined) {
    totalCountPerGroup[groupKey] = 0;
  }

  return {
    addOrderArticle,
    orderArticles: orderArticles[groupKey] ?? [],
    totalCountPerGroup,
    allOrderArticles: orderArticles,
  };
}
