import { ButtonBase, lighten, LinearProgress, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Image from "../../../../../../global/components/Image.tsx";
import { useDialog } from "../../../../../../global/utils/dialog/DialogProvider.tsx";
import SlideUpTransition from "../../../../../../global/components/SlideUpTransition.tsx";
import { PiggyRewardV2 } from "../../../../../../global/utils/redux/api/piggyRewardsApi.ts";
import { ClaimPiggyRewardDialogV2 } from "./ClaimPiggyRewardDialogV2.tsx";
import { useClaimPiggyRewardDialogV2 } from "./useClaimPiggyRewardDialogV2.tsx";

export default function PiggyRewardItemV2({
  item,
  piggyPoints,
}: {
  item: PiggyRewardV2;
  piggyPoints: number | undefined;
}) {
  const balance: number = piggyPoints ?? 0;
  const { openDialog } = useDialog();

  const { piggyRewardLoading, onPiggyRewardDialogClose } = useClaimPiggyRewardDialogV2(item);

  const balanceOverPointsRequiredPercentage = Math.min(100, (balance / item.required_credits) * 100);
  return (
    <Paper
      data-cy="piggy-reward-item"
      sx={{ borderRadius: 4, width: 400, height: 1, display: "flex", flexDirection: "column" }}
    >
      <ButtonBase
        onClick={() => {
          openDialog({
            children: (
              <ClaimPiggyRewardDialogV2
                item={item}
                piggyPoints={balance}
                props={{
                  onClose: onPiggyRewardDialogClose,
                }}
              />
            ),
            TransitionComponent: SlideUpTransition,
          });
        }}
        sx={{
          width: 1,
          borderRadius: 3,
          display: "grid",
          gridTemplateColumns: item.media?.value ? "33% 1fr" : "1fr",
          p: 1,
          ...(piggyRewardLoading
            ? {
                filter: "opacity(50%)",
                pointerEvents: "none",
              }
            : {}),
          flex: 1,
          height: 0,
        }}
      >
        {item.media?.value ? (
          <Image
            src={item.media.value}
            style={{
              objectFit: "contain",
              alignSelf: "center",
              justifySelf: "center",
              width: "calc(100% - 16px)",
            }}
          />
        ) : null}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            boxSizing: "content-box",
            height: "100%",
          }}
        >
          <Typography sx={{ fontWeight: 800 }}>{item.title}</Typography>
          <Typography
            sx={{
              color: lighten("#000", 0.6),
              paddingRight: 1,
              width: 1,
              fontSize: "1.2rem",
              fontStyle: "italic",
              textOverflow: "ellipsis",
              lineClamp: "2",
              WebkitLineClamp: "2",
              overflow: "hidden",
            }}
            className={"JS-ArticleContent-Description"}
            dangerouslySetInnerHTML={{
              __html: (item.description ?? "").replace(/(<([^>]+)>)/gi, ""),
            }}
          />
          <Typography color="text.secondary">{item.required_credits} points</Typography>
        </Box>
      </ButtonBase>
      <LinearProgress
        sx={{ w: 1, height: "12px", borderBottomLeftRadius: "12px", borderBottomRightRadius: "12px" }}
        color={balanceOverPointsRequiredPercentage === 100 ? "success" : "primary"}
        variant="determinate"
        value={balance == null ? 0 : balanceOverPointsRequiredPercentage}
      />
    </Paper>
  );
}
