import { useDialog } from "../../../../../../global/utils/dialog/DialogProvider.tsx";
import { Button, DialogActions, DialogContent, DialogTitle, lighten, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../../../../global/components/FormattedMessageJamezz.tsx";
import Image from "../../../../../../global/components/Image.tsx";
import { PiggyRewardV2 } from "../../../../../../global/utils/redux/api/piggyRewardsApi.ts";

export function ClaimPiggyRewardDialogV2({
  item,
  props: { onClose },
  piggyPoints,
}: {
  item: PiggyRewardV2;
  piggyPoints: number | undefined;
  props: {
    onClose: (claimed: boolean) => void;
  };
}) {
  const hasEnoughPoints = piggyPoints != null && item.required_credits <= piggyPoints;
  const { closeDialog } = useDialog();

  return (
    <>
      <DialogTitle>
        <FormattedMessageJamezz id="Piggy.rewards.confirmationDialog.title" />
        {hasEnoughPoints ? (
          <Typography
            variant="body2"
            sx={{
              color: lighten("#000", 0.6),
            }}
          >
            <FormattedMessageJamezz id="Piggy.rewards.confirmationDialog.warning.noRefunds" />
          </Typography>
        ) : null}
      </DialogTitle>
      <DialogContent>
        {item.media?.value ? (
          <Image
            src={item.media?.value}
            style={{
              objectFit: "contain",
              alignSelf: "center",
              justifySelf: "center",
              maxWidth: "100%",
            }}
          />
        ) : null}
        <Typography variant="body1">{item.title}</Typography>
        {hasEnoughPoints ? (
          <>
            <Typography
              variant="body1"
              fontStyle="italic"
              sx={{
                color: lighten("#000", 0.6),
              }}
              dangerouslySetInnerHTML={{ __html: item.description ?? "" }}
            />
            <Typography variant="body1">
              <FormattedMessageJamezz
                id="Piggy.rewards.confirmationDialog.cost"
                values={{
                  cost: item.required_credits,
                }}
              />
            </Typography>
          </>
        ) : (
          <>
            <Typography sx={{ mt: 2, mb: 0.5 }} data-cy="piggy-dialog-claimReward-notEnoughPoints" color="error">
              <FormattedMessageJamezz id="Piggy.rewards.confirmationDialog.error.notEnoughPoints" />
            </Typography>
            <Typography>
              <FormattedMessageJamezz
                id="Piggy.rewards.confirmationDialog.error.pointsNeeded"
                values={{
                  balance: piggyPoints ?? 0,
                  pointsRequired: item.required_credits,
                }}
              />
            </Typography>
          </>
        )}

        <DialogActions sx={{ display: "flex", width: "100%", gap: 4 }}>
          {hasEnoughPoints ? (
            <>
              <Button
                sx={{ flexGrow: 1 }}
                variant="outlined"
                onClick={() => {
                  onClose(false);
                  closeDialog();
                }}
              >
                <FormattedMessageJamezz id="Cancel" />
              </Button>
              <Button
                sx={{ flexGrow: 1 }}
                data-cy="piggy-btn-claim-reward"
                variant="contained"
                onClick={async () => {
                  onClose(true);
                  closeDialog();
                }}
              >
                <FormattedMessageJamezz id="Piggy.rewards.confirmationDialog.claimReward" />
              </Button>
            </>
          ) : (
            <Button
              sx={{ flexGrow: 1 }}
              variant="outlined"
              onClick={() => {
                onClose(false);
                closeDialog();
              }}
            >
              <FormattedMessageJamezz id="Ok" />
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </>
  );
}
