import { MediaUrl } from "../../global/utils/redux/api/menuDataApi.ts";
import { useAppSelector } from "../../global/utils/redux/store.tsx";
import { MediaUrlKeysOfSalesarea } from "../../types/shared/Salesarea.ts";
import Box from "@mui/material/Box";
import { BoxProps } from "@mui/material";
import ReactPlayer from "react-player";
import Image from "../../global/components/Image.tsx";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { RefObject, useRef } from "react";
import { differenceInMilliseconds } from "date-fns";

type relativeTo = "container" | "window";

export default function BackgroundMediaUrlFromSalesarea({
  keyName,
  fallbackBackgroundColor,
  fallbackBackground,
  sxWrapper,
  innerRef,
  relativeTo = "container",
  ...rest
}: {
  keyName: MediaUrlKeysOfSalesarea;
  sxWrapper?: BoxProps["sx"];
  innerRef?: RefObject<HTMLElement>;
  fallbackBackgroundColor?: CSSProperties["backgroundColor"];
  fallbackBackground?: CSSProperties["background"];
  relativeTo?: relativeTo;
} & BoxProps) {
  const media_url = useAppSelector((state) => state.global.salesarea?.media_urls?.[keyName]?.[0]);
  return (
    <BackgroundUsingMediaUrl
      mediaUrl={media_url}
      fallbackBackgroundColor={fallbackBackgroundColor}
      fallbackBackground={fallbackBackground}
      sxWrapper={sxWrapper}
      relativeTo={relativeTo}
      innerRef={innerRef}
      {...rest}
    />
  );
}

export function BackgroundUsingMediaUrl({
  mediaUrl,
  children,
  sx,
  sxWrapper,
  styleReactPlayer,
  fallbackBackgroundColor,
  fallbackBackground,
  innerRef,
  relativeTo = "container",
  ...rest
}: {
  mediaUrl?: MediaUrl;
  sxWrapper?: BoxProps["sx"];
  styleReactPlayer?: CSSProperties;
  innerRef?: RefObject<HTMLElement>;
  fallbackBackgroundColor?: CSSProperties["backgroundColor"];
  fallbackBackground?: CSSProperties["background"];
  relativeTo?: relativeTo;
} & BoxProps) {
  const isVideo = mediaUrl?.mime_type.startsWith("video/");
  const isImage = mediaUrl?.mime_type.startsWith("image/");

  const playerRef = useRef<ReactPlayer>(null);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: 1,
          height: 1,
          backgroundColor: fallbackBackgroundColor,
          background: !isVideo && !isImage ? fallbackBackground : undefined,
          ...sxWrapper,
        }}
        ref={innerRef}
      >
        {isVideo ? (
          <ReactPlayer
            style={{ position: relativeTo == "container" ? "absolute" : "fixed", top: 0, left: 0, ...styleReactPlayer }}
            width={"100%"}
            height={"100%"}
            url={mediaUrl?.conversions?.optimized?.url}
            loop={true}
            playing={true}
            controls={false}
            playsinline={true}
            muted={true}
            config={{}}
            onDuration={(duration) => {
              if (playerRef.current) {
                const date = new Date("2024-11-27Z00:00:00");

                const diff = differenceInMilliseconds(new Date(), date);
                const seekTo = diff % (duration * 1000);
                playerRef.current?.seekTo(seekTo / 1000.0, "seconds");
              }
            }}
            ref={playerRef}
          />
        ) : isImage ? (
          <Image
            src={mediaUrl?.conversions?.optimized?.url}
            style={{
              width: "100%",
              height: "100%",
              position: relativeTo == "container" ? "absolute" : "fixed",
              top: 0,
              left: 0,
              objectFit: "cover",
            }}
          />
        ) : null}
        <Box sx={{ isolation: "isolate", ...sx }} {...rest}>
          {children}
        </Box>
      </Box>
    </>
  );
}

export function useFindScheduledMedia() {
  const scheduled_media = useAppSelector((state) => state.global.salesarea.kioskv5_media.scheduled_media);

  if (scheduled_media.enabled) {
    const active_schedule = scheduled_media.schedule.find((s) => s.is_open);
    if (active_schedule) {
      return active_schedule.media_urls?.scheduled_homescreen_media?.[0];
    }
  }
}
