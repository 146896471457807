import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import PiggyRewardsV2 from "./PiggyRewardsV2.tsx";
import React from "react";
import PiggyRewardsV1 from "./PiggyRewardsV1.tsx";
import { BoxProps } from "@mui/material";

export default function PiggyRewards(props: { showBalance: boolean; wrapperSx?: BoxProps["sx"] }) {
  const rewardsSystem = useAppSelector((state) => state.global.salesarea?.piggy?.custom_data?.rewards_system);
  if (rewardsSystem == "V2") {
    return <PiggyRewardsV2 {...props} />;
  } else {
    return <PiggyRewardsV1 {...props} />;
  }
}
