import { Button, Chip, Collapse, Grid, IconButton, IconButtonProps, Paper, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useCurrency, { CurrencyLocation } from "../../../global/utils/useCurrency";
import { Add, Delete, Loyalty, Remove } from "@mui/icons-material";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { orderArticlesPushedByUser } from "../../../global/utils/redux/shoppingCartSlice";

import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import OrderArticle, { getTotalPrice, OrderArticleOrigin } from "../../../global/utils/models/order/OrderArticle";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import OrderOptionGroup, {
  countUniqueOrderArticlesInOrderOptionGroups,
} from "../../../global/utils/models/order/OrderOptionGroup";
import OrderArticleSelectorPage from "../OrderArticleSelectorPage/OrderArticleSelectorPage";
import SlideUpTransition from "../../../global/components/SlideUpTransition";
import Image from "../../../global/components/Image";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import AreYouSureDialog from "../../components/AreYouSureDialog";
import { getArticleName } from "../../../global/utils/models/menu/Article";
import usePrintOptionOrderArticle from "../OrderArticleSelectorPage/usePrintOptionOrderArticle";
import { selectVoucherDiscounts } from "../../../global/utils/vouchersV2/selectors/selectVoucherDiscounts.ts";
import PercentageDiscount from "../../../assets/images/PercentageDiscount.svg?react";
import { useMaxAlcoholicItemsCheck } from "../../../global/components/MaxAlcoholicItems/MaxAlcoholicItems";
import { AgeCheckResult } from "../../../global/utils/ageCheck/ageCheckUtils.ts";
import { selectAppLanguage } from "../../components/LanguageSelector/useLanguage.ts";
import useAddOrderArticleToShoppingCart from "../../../global/utils/order/useAddOrderArticleToShoppingCart.tsx";
import { getArticleById } from "../../../global/utils/models/menu/Menu.ts";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap.ts";
import { selectShoppingCartCountPerArticleId } from "../../../global/utils/redux/selectors/selectShoppingCartCountPerArticleId.ts";
import { selectSalesAreaPriceLineId } from "../../../global/utils/useSalesAreaPriceLineId.ts";

interface Props {
  orderArticle: OrderArticle;
  index?: number;
  usePiggyVouchers?: boolean;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function OrderArticleContent({ orderArticle, index, usePiggyVouchers }: Props) {
  const lang = useAppSelector(selectAppLanguage);
  const toCurrency = useCurrency({ location: CurrencyLocation.ArticleDetail });
  const dispatch = useAppDispatch();
  const { openDialog } = useDialog();
  const articleEditable = orderArticle.added_origin === OrderArticleOrigin.MENU || orderArticle.added_origin === null;
  const collapsedOptionEnabled = useAppSelector((state) => state.global.salesarea.kiosk_compact_options);

  const hasOptionsToCollapse =
    countUniqueOrderArticlesInOrderOptionGroups(orderArticle.orderOptionGroups) > 1 ||
    (orderArticle.extraOrderArticles?.length ?? 0) > 1;
  const [collapsedOptions, setCollapsedOptions] = useState(true);

  const countPrefix = useMemo(() => {
    return orderArticle.count > 1 ? orderArticle.count + " x " : "";
  }, [orderArticle]);

  const printOptionOrderArticle = usePrintOptionOrderArticle("JS-OrderArticleContent-Option");
  const checkMaxAlcoholicItems = useMaxAlcoholicItemsCheck();
  const printOrderOptionGroup = useCallback<any>(
    (orderOptionGroup: OrderOptionGroup) => {
      return orderOptionGroup.orderArticles
        .filter((orderArticle) => orderArticle.count > 0)
        .reduce((elements: ReactNode[], orderArticle) => {
          elements.push(printOptionOrderArticle(orderArticle));
          elements = elements.concat(
            orderArticle.orderOptionGroups.map((orderOptionGroup) => printOrderOptionGroup(orderOptionGroup))
          );
          return elements;
        }, []);
    },
    [printOptionOrderArticle]
  );

  // either the orderOptionGroups array is empty
  // or all articles in the option groups have a count of 0
  const orderOptionsGroupEmpty = countUniqueOrderArticlesInOrderOptionGroups(orderArticle.orderOptionGroups) === 0;
  const orderExtraEmpty = (orderArticle.extraOrderArticles ?? []).length === 0;
  const piggyDiscounts = useAppSelector(selectVoucherDiscounts);
  const options =
    orderOptionsGroupEmpty && orderExtraEmpty ? null : (
      <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: 5 }}>
        {orderArticle.orderOptionGroups.map((orderOptionGroup) => printOrderOptionGroup(orderOptionGroup))}
        {(orderArticle.extraOrderArticles ?? []).map((orderArticle) =>
          printOptionOrderArticle(orderArticle, orderArticle.count)
        )}
      </Box>
    );

  const articleFromStore =
    getArticleById(useAppSelector(selectArticlesMap), orderArticle.article.id) ?? orderArticle.article;
  const countOfArticleIdInShoppingCart =
    useAppSelector((state) => selectShoppingCartCountPerArticleId(state)[orderArticle.article.id]) ?? 0;
  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart({ allowUpsell: false });
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  return (
    <Grid item xs={12} data-cy="order-article-content">
      <Paper
        className="JS-OrderArticleContent-Root"
        sx={{
          marginY: 1,
          position: "relative",
          borderRadius: 3,
          paddingBottom: 4,
          display: "grid",
          gridTemplateColumns: 0 + "px 20% minmax(0, 2fr) 1fr " + 0 + "px",
          gridTemplateRows: "auto 1fr",
          columnGap: 4,
          rowGap: 4,
          alignItems: "start",
          gridTemplateAreas: `
              "Banner Banner Banner Banner Banner"
              "WhitespaceLeft Image Body Amount-buttons WhitespaceRight"
          `,
          ...(orderArticle.added_origin === OrderArticleOrigin.SYSTEM && {
            opacity: 0.6,
            "& *": {
              userSelect: "none",
            },
          }),
          overflow: "hidden",
        }}
      >
        <Box sx={{ gridArea: "Banner" }}>
          {orderArticle.added_origin === OrderArticleOrigin.PIGGY ? (
            <Box
              sx={(theme) => ({
                gridArea: "Banner",
                width: 1,
                height: "48px",
                backgroundColor: theme.palette.primary.main,
                display: "flex",
                px: 2,
                alignItems: "center",
              })}
            >
              <Loyalty sx={{ fontSize: "1em", mr: 1 }} />
              <Typography sx={{ fontSize: "0.8em" }}>
                <FormattedMessageJamezz id="Piggy.rewards.article.your-reward" />
              </Typography>
            </Box>
          ) : null}
          <Collapse
            sx={(theme) => ({
              background: theme.palette.error.main,
              width: 1,
              px: 1,
              display: "flex",
              alignItems: "center",
            })}
            in={
              articleFromStore != null &&
              countOfArticleIdInShoppingCart > articleFromStore.stock &&
              articleFromStore.stock > 0
            }
          >
            <Box>{articleFromStore?.stock === 0 ? "Out of stock" : "Only " + articleFromStore?.stock + " left"}</Box>
          </Collapse>
        </Box>
        {orderArticle.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ? (
          <Box
            sx={{ height: 200, width: 200, gridArea: "Image", alignSelf: "center" }}
            className={"JS-OrderArticleContent-ImageWrapper"}
          >
            <Image
              srcSet={orderArticle.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset}
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
            />
          </Box>
        ) : (
          <Box sx={{ gridArea: "Image", width: 0 }}></Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gridArea: "Body",
            overflow: "clip",
            alignSelf: "center",
          }}
        >
          <Typography className={"JS-OrderArticleContent-Title"} sx={{ fontWeight: 800 }}>
            {countPrefix}
            {getArticleName(orderArticle.article, lang)}
          </Typography>

          {usePiggyVouchers &&
          index != null &&
          piggyDiscounts.discountsPerOrderArticle[orderArticle.uuid]?.discount > 0 ? (
            <>
              <Typography
                className={"JS-OrderArticleContent-Price"}
                sx={{ textDecoration: "line-through", marginTop: 1, color: "rgb(222, 51, 51)" }}
              >
                {toCurrency(getTotalPrice(orderArticle, priceLineId))}
              </Typography>

              <Stack direction={"column"} alignItems={"flex-start"}>
                <Typography sx={{ fontWeight: 800, marginTop: 1, color: "green" }}>
                  {toCurrency(
                    getTotalPrice(orderArticle, priceLineId) -
                      piggyDiscounts.discountsPerOrderArticle[orderArticle.uuid]?.discount
                  )}
                </Typography>
                <Box>
                  <Chip
                    icon={<PercentageDiscount style={{ color: "#fff", width: 54, height: 54 }} />}
                    sx={{
                      p: 2,
                      py: 3,
                      backgroundColor: "green",
                      color: "white",
                      ".MuiChip-icon": { color: "#fff" },
                      borderRadius: 32,
                    }}
                    label={"Voucher applied"}
                  />
                </Box>
              </Stack>
            </>
          ) : (
            <Typography className={"JS-OrderArticleContent-Price"} sx={{ fontWeight: 800, marginTop: 1 }}>
              {toCurrency(getTotalPrice(orderArticle, priceLineId))}
            </Typography>
          )}
          {collapsedOptionEnabled && hasOptionsToCollapse ? (
            <Box sx={{ display: "grid", gridTemplateColumns: "minmax(0, 1fr) 64px" }}>
              <Collapse in={!collapsedOptions} collapsedSize={95}>
                {options}
              </Collapse>
              <ExpandMore expand={!collapsedOptions} onClick={() => setCollapsedOptions((v) => !v)} sx={{ margin: 0 }}>
                <ExpandMoreIcon />
              </ExpandMore>
            </Box>
          ) : (
            options
          )}
        </Box>
        {orderArticle.editable && articleEditable ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              gridArea: "Amount-buttons",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: 1 }}>
              <IconButton
                sx={{ paddingX: 3 }}
                size={"large"}
                onClick={(e) => {
                  e.stopPropagation();
                  if (orderArticle.count === 1) {
                    openDialog({
                      children: (
                        <AreYouSureDialog
                          message={
                            <>
                              <FormattedMessageJamezz
                                id={"Are you sure you want to remove {articleName}?"}
                                values={{ articleName: getArticleName(orderArticle.article, lang) }}
                              />
                            </>
                          }
                          onOk={() => {
                            dispatch(orderArticlesPushedByUser([{ ...orderArticle, count: -1 }]));
                          }}
                        />
                      ),
                      TransitionComponent: SlideUpTransition,
                    });
                  } else {
                    dispatch(orderArticlesPushedByUser([{ ...orderArticle, count: -1 }]));
                  }
                }}
              >
                {orderArticle.count === 1 ? (
                  <Delete sx={{ color: "primary.light" }} className="JS-OrderArticleContent-MinusButton" />
                ) : (
                  <Remove sx={{ color: "primary.light" }} className="JS-OrderArticleContent-MinusButton" />
                )}
              </IconButton>
              <Typography
                data-cy="order-article-count"
                sx={{
                  flex: 1,
                  textAlign: "center",
                  minWidth: 60,
                }}
              >
                {orderArticle.count}
              </Typography>
              <IconButton
                sx={{ paddingX: 3 }}
                size={"large"}
                onClick={async (e) => {
                  e.stopPropagation();
                  const result = await checkMaxAlcoholicItems({ ...orderArticle, count: 1 });

                  if (result === AgeCheckResult.Accepted) {
                    addOrderArticleToShoppingCart({ ...orderArticle, count: 1 }).catch((err) => console.log(err));
                  }
                }}
              >
                <Add sx={{ color: "primary.light" }} className="JS-OrderArticleContent-PlusButton" />
              </IconButton>
            </Box>
            {orderArticle.orderOptionGroups.length > 0 ? (
              <Button
                className={"JS-OrderArticleContent-ChangeOrderButton"}
                sx={{
                  marginY: 3,
                  paddingX: 3,
                  color: "primary.light",
                  marginX: "16px",
                  width: "calc(100% - 32px)",
                }}
                size={"large"}
                onClick={(e) => {
                  e.stopPropagation();

                  openDialog({
                    children: <OrderArticleSelectorPage orderArticle={JSON.parse(JSON.stringify(orderArticle))} />,
                    fullScreen: "almostFullScreen",
                    TransitionComponent: SlideUpTransition,
                  });
                }}
              >
                <FormattedMessageJamezz id={"EditItem"} />
              </Button>
            ) : null}
          </Box>
        ) : (
          <Box sx={{ gridArea: "Amount-buttons" }}></Box>
        )}
      </Paper>
    </Grid>
  );
}
