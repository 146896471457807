import Article, { getArticleName } from "../../../../global/utils/models/menu/Article.ts";
import OrderArticle, { initOrderArticle } from "../../../../global/utils/models/order/OrderArticle.ts";
import store, { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { selectAppLanguage } from "../../../components/LanguageSelector/useLanguage.ts";
import { ButtonBase, Fade, Stack, Typography, useTheme } from "@mui/material";
import { selectArticlesMap } from "../../../../global/utils/redux/selectors/selectArticlesMap.ts";
import Image from "../../../../global/components/Image.tsx";
import PlusOrMinus from "./PlusOrMinus.tsx";
import React, { useCallback } from "react";
import _ from "lodash";

export default function CampaignArticleItem({
  article,
  addOrderArticle,
  count,
  columnIndex,
  maxCount,
  orderArticles,
}: {
  article: Article;
  addOrderArticle: (orderArticle: OrderArticle) => void;
  count: number;
  columnIndex: number;
  maxCount: number;
  orderArticles: OrderArticle[];
}) {
  const lang = useAppSelector(selectAppLanguage);

  const theme = useTheme();

  const textColor = theme.palette.getContrastText(theme.palette.primary.light);

  const onPlus = useCallback(() => {
    const orderArticle = initOrderArticle(selectArticlesMap(store.getState()), article, 1);
    if (orderArticle) {
      addOrderArticle(orderArticle);
    }
  }, [addOrderArticle, article]);

  return (
    <>
      <ButtonBase
        sx={{
          zIndex: 0,
          transition: "background-color 200ms",
          backgroundColor: count > 0 ? "primary.light" : undefined,
          width: 1,
          gridArea: `${1} / ${columnIndex + 1} / ${4} / ${columnIndex + 2}`,
          boxShadow: "4px 4px 5px 0px rgba(0,0,0,0.1)",
          borderRadius: 3,
        }}
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          onPlus();
        }}
      ></ButtonBase>
      {article.imageMediaUrls[0]?.conversions?.responsive?.srcset ? (
        <Image
          style={{
            width: "80%",
            gridArea: `${1} / ${columnIndex + 1} / ${2} / ${columnIndex + 2}`,
            zIndex: 1,
            pointerEvents: "none",
          }}
          srcSet={article.imageMediaUrls[0]?.conversions?.responsive?.srcset}
        />
      ) : null}
      <Typography
        textAlign={"center"}
        fontSize={"1.3rem"}
        color={count > 0 ? textColor : undefined}
        sx={{
          width: 1,
          gridArea: `${2} / ${columnIndex + 1} / ${3} / ${columnIndex + 2}`,
          px: 2,
          zIndex: 1,
          pointerEvents: "none",
        }}
      >
        {getArticleName(article, lang)}
      </Typography>
      {/*{articleDescription}*/}
      {/*<Box sx={{ gridArea: `${4} / ${columnIndex + 1} / ${5} / ${columnIndex + 2}`, px: 2, zIndex: 1 }}>*/}
      {/*  <PriceWrapper*/}
      {/*    price={article.price}*/}
      {/*    vaprice={article.vaprice}*/}
      {/*    localPrice={article.customData?.local_price > 0 ? article.customData?.local_price : null}*/}
      {/*    articleId={article.id}*/}
      {/*  />*/}
      {/*</Box>*/}

      <Fade in={maxCount > 1}>
        <Stack
          direction={"row"}
          sx={{
            gridArea: `${3} / ${columnIndex + 1} / ${4} / ${columnIndex + 2}`,
            zIndex: 1,
            py: 2,
            visibility: count == 0 ? "collapse" : undefined,
          }}
          alignItems={"center"}
        >
          <PlusOrMinus
            maxCount={maxCount}
            type={"minus"}
            onClick={() => {
              const orderArticle = _.last(orderArticles) ?? null;
              if (orderArticle) {
                addOrderArticle({ ...orderArticle, count: -1 });
              }
            }}
          />
          <Typography color={textColor} fontSize={"2rem"} sx={{ px: 4 }}>
            {count}
          </Typography>
          <PlusOrMinus
            maxCount={maxCount}
            type={"plus"}
            onClick={() => {
              onPlus();
            }}
          />
        </Stack>
      </Fade>
    </>
  );
}
