import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

// Find all campaigns for an article with O(1) performance
export const selectCampaignArticlesMap = createSelector(
  [(state: RootState) => state.menuData.campaign_products],
  (campaign_products) => {
    const campaignIdArticleIdsMap: Record<string, string[]> = {};

    campaign_products.forEach((pivot) => {
      if (!campaignIdArticleIdsMap[pivot.campaign_id]) {
        campaignIdArticleIdsMap[pivot.campaign_id] = [];
      }
      campaignIdArticleIdsMap[pivot.campaign_id].push(pivot.product_id);
    });

    return campaignIdArticleIdsMap;
  }
);
