import Box from "@mui/material/Box";
import { useRef } from "react";
import { Collapse, IconButton, Typography } from "@mui/material";
import store, { useAppSelector } from "../../../../global/utils/redux/store";
import { piggyGiftcardRemoved } from "../../../../global/utils/redux/piggySlice";
import useCurrency, { CurrencyLocation } from "../../../../global/utils/useCurrency";
import { useIntl } from "react-intl";
import { Delete, Info } from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";
import { useFocusCustomField } from "../../../pages/CheckoutPage/CustomFields/useFocusCustomField";
import JTooltip from "../../../../kiosk/components/JamezzMUI/JTooltip";
import { useCustomTexts } from "../../../../global/utils/useCustomTexts";
import { CSSProperties } from "@mui/material/styles/createMixins";

export default function PiggyGiftcardOrderPage({ color }: { color?: CSSProperties["color"] }) {
  const toCurrency = useCurrency({ location: CurrencyLocation.Checkout });

  const piggyGiftcards = useAppSelector((state) => state.piggy.giftcards);

  const intl = useIntl();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const enabled = useAppSelector((state) => state.global.salesarea.piggy.giftcards.enabled);
  const customFieldName = intl.formatMessage({ id: "Piggy Giftcards" });
  useFocusCustomField(inputRef, customFieldName);
  const ct = useCustomTexts(["loyalty gift card - title"]);

  return (
    <Collapse sx={{ width: 1 }} in={Boolean(enabled && piggyGiftcards.length !== 0)} unmountOnExit>
      <Box sx={{ mb: 2, border: "0px solid #66666666", borderRadius: 3, width: 1, color: color ?? "white" }}>
        <Box sx={{ display: "inline-flex", justifyContent: "space-between", width: 1 }}>
          <Typography>{ct["loyalty gift card - title"] ?? "Piggy Giftcards"}</Typography>
          <JTooltip
            title={intl.formatMessage({
              id: "The used amount will be subtracted from your giftcard. If the giftcard still has a balance, it can be used at a later moment in time.",
            })}
          >
            <IconButton>
              <Info style={{ color: color ?? "white" }} />
            </IconButton>
          </JTooltip>
        </Box>
        <Box sx={{ mt: 1 }}>
          <TransitionGroup style={{ width: "100%" }}>
            {piggyGiftcards.map((giftcard) => {
              return (
                <Collapse key={giftcard.uuid}>
                  <Box
                    key={giftcard.uuid}
                    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                  >
                    <Typography>*****{giftcard.hash.substring(5)}</Typography>
                    <Typography>{toCurrency(giftcard.amount_in_cents / 100)}</Typography>
                    <JTooltip
                      title={intl.formatMessage({ id: "PiggyGiftCard.remove" })}
                      arrow
                      onClose={() => {}}
                      onOpen={() => {}}
                      disableInteractive
                    >
                      <IconButton
                        onClick={() => {
                          store.dispatch(piggyGiftcardRemoved(giftcard));
                        }}
                      >
                        <Delete style={{ color: color ?? "white" }} />
                      </IconButton>
                    </JTooltip>
                  </Box>
                </Collapse>
              );
            })}
          </TransitionGroup>
        </Box>
      </Box>
    </Collapse>
  );
}
