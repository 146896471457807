import { Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../../../global/components/FormattedMessageJamezz.tsx";
import { usePreloadPrepaidData } from "../../../../utils/piggy/usePiggyLogin.tsx";
import JTooltip from "../../../JamezzMUI/JTooltip.tsx";

export default function PrepaidBalance({ isCompactView }: { isCompactView?: boolean }) {
  const data = usePreloadPrepaidData();

  return (
    <>
      {data?.prepaid_balance?.balance_in_cents == null ? null : (
        <JTooltip
          title={
            <FormattedMessageJamezz id={"My balance: {balance}"} values={{ balance: data?.prepaid_balance.balance }} />
          }
        >
          <Typography
            sx={{
              fontSize: isCompactView ? "1rem" : "2rem",
              backgroundColor: "primary.light",
              px: 2,
              borderRadius: 3,
            }}
            data-cy={"piggy-prepaid-balance"}
          >
            {data.prepaid_balance.balance}
          </Typography>
        </JTooltip>
      )}
    </>
  );
}
