import { useMemo } from "react";
import { ChainPart, ChainPartPriority, ChainPartResult, createChainPart } from "./chainParts";
import useBarCodeChecker from "../useBarCodeChecker.tsx";

export function useBarcodeChainPart(barcodeScannerEnabled: boolean): ChainPart {
  const barCodeChecker = useBarCodeChecker();

  return useMemo(() => {
    return createChainPart(ChainPartPriority.High, (data: string) => {
      return new Promise<ChainPartResult>((resolve) => {
        if (barcodeScannerEnabled && barCodeChecker(data)) {
          resolve("found");
        } else {
          resolve("not_found");
        }
      });
    });
  }, [barcodeScannerEnabled, barCodeChecker]);
}
