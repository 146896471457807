import { BoxProps, Divider, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz.tsx";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { useListVouchersQuery } from "../../../../global/utils/redux/api/piggyApi.ts";
import PiggyVoucherItem from "./PiggyVoucherItem.tsx";
import { VoucherV2 } from "../../../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import {
  calculateDiscountForVoucher,
  selectVoucherDiscounts,
} from "../../../../global/utils/vouchersV2/selectors/selectVoucherDiscounts.ts";
import { selectSalesAreaPriceLineId } from "../../../../global/utils/useSalesAreaPriceLineId.ts";
import _ from "lodash";
import { useMemo } from "react";
import { selectArticleArticlegroupsMapUnfiltered } from "../../../../global/utils/redux/selectors/selectArticleArticlegroupsMapUnfiltered.ts";
import { selectArticlegroupsMap } from "../../../../global/utils/redux/selectors/selectArticlegroupsMap.ts";

export function usePotentialVouchers(vouchersV2: VoucherV2[]) {
  const { discountsPerOrderArticle } = useAppSelector(selectVoucherDiscounts);
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  const articleArticlegroupsMap = useAppSelector(selectArticleArticlegroupsMapUnfiltered);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const items = useAppSelector((state) => state.shoppingCart.items);
  return useMemo(() => {
    return vouchersV2.filter((voucherV2) => {
      const discounts = calculateDiscountForVoucher(
        voucherV2,
        items,
        priceLineId,
        _.cloneDeep(discountsPerOrderArticle),
        articleArticlegroupsMap,
        articlegroupsMap
      );

      return _.chain(discounts).values().sumBy("discount").value() > 0;
    });
  }, [articleArticlegroupsMap, articlegroupsMap, discountsPerOrderArticle, items, priceLineId, vouchersV2]);
}

export default function PiggyVouchers({
  wrapperSx,
  displayTitle = true,
}: {
  wrapperSx?: BoxProps["sx"];
  displayTitle?: boolean;
}) {
  const piggyVouchersEnabled = useAppSelector((state) => state.global.salesarea.piggy.vouchers.enabled);
  const contactIdentifierScan = useAppSelector((state) => state.piggy.contactIdentifierScan);
  const { data: vouchersList, isFetching } = useListVouchersQuery(
    { contactIdentifier: contactIdentifierScan?.code ?? "", page: 1, status: "ACTIVE" },
    {
      skip: !piggyVouchersEnabled || !contactIdentifierScan,
    }
  );

  if ((vouchersList?.data.length == 0 && !isFetching) || !piggyVouchersEnabled) {
    return null;
  }
  return (
    <Box sx={wrapperSx}>
      {displayTitle ? (
        <Divider
          sx={{
            "&::before": {
              borderTopWidth: "4px",
            },
            "&::after": {
              borderTopWidth: "4px",
            },
          }}
        >
          <FormattedMessageJamezz id="Piggy.order-page.vouchers-list.header" />
        </Divider>
      ) : null}
      <Box
        data-cy="piggy-rewards-container"
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "scroll",
          pt: 2,
          pb: 1.5,
        }}
      >
        {isFetching ? (
          <>
            <Box sx={{ flex: "0 0 auto", display: "flex", justifyContent: "center", px: 2 }}>
              <Skeleton variant="rectangular">
                <Box sx={{ height: "160px", width: "320px" }} />
              </Skeleton>
            </Box>
            <Box sx={{ flex: "0 0 auto", display: "flex", justifyContent: "center", px: 2 }}>
              <Skeleton variant="rectangular">
                <Box sx={{ height: "160px", width: "320px" }} />
              </Skeleton>
            </Box>
            <Box sx={{ flex: "0 0 auto", display: "flex", justifyContent: "center", px: 2 }}>
              <Skeleton variant="rectangular">
                <Box sx={{ height: "160px", width: "320px" }} />
              </Skeleton>
            </Box>
          </>
        ) : vouchersList != null ? (
          <>
            {vouchersList.data.map((piggyVoucher) => (
              <Box
                key={piggyVoucher.uuid}
                sx={{
                  flex: "0 0 auto",
                  display: "flex",
                  justifyContent: "center",
                  px: 2,
                }}
              >
                <PiggyVoucherItem item={piggyVoucher} />
              </Box>
            ))}
          </>
        ) : (
          <FormattedMessageJamezz id="Piggy.order-page.vouchers.loading-error" />
        )}
      </Box>
    </Box>
  );
}
