import OrderOptionGroup, { countOrderArticles } from "../../../global/utils/models/order/OrderOptionGroup.ts";
import OrderArticle from "../../../global/utils/models/order/OrderArticle.ts";
import { optionGroupType, Type } from "../../../global/utils/orderSelector/useOptionGroup.ts";
import { getPropertyOfOptionGroup } from "../../../global/utils/models/menu/OptionGroup.ts";

export function shouldAutoGotoNextOptionPage(orderOptionGroup: OrderOptionGroup, orderArticle: OrderArticle) {
  const radio = optionGroupType(orderOptionGroup, orderArticle.article) === Type.Radio;
  const optional =
    optionGroupType(orderOptionGroup, orderArticle.article) === Type.Optional &&
    getPropertyOfOptionGroup(orderOptionGroup.optionGroup, orderArticle.article, "minCount") === 0 &&
    getPropertyOfOptionGroup(orderOptionGroup.optionGroup, orderArticle.article, "maxCount") === 1;

  const maxCountReached =
    getPropertyOfOptionGroup(orderOptionGroup.optionGroup, orderArticle.article, "maxCount") > 0 &&
    getPropertyOfOptionGroup(orderOptionGroup.optionGroup, orderArticle.article, "maxCount") ===
      countOrderArticles(orderOptionGroup);

  return (radio || optional || maxCountReached) && countOrderArticles(orderOptionGroup) > 0;
}
