import { ReactNode } from "react";
import Box from "@mui/material/Box";

export default function CampaignMenuItemGrid({
  numberOfArticles,
  children,
}: {
  numberOfArticles: number;
  children: ReactNode;
}) {
  const numberOfColumns = numberOfArticles;
  const numberOfRows = 3;
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${numberOfColumns}, calc(33% - 20px))`,
        gridTemplateRows: `repeat(${numberOfRows}, auto)`,
        columnGap: 4,
        rowGap: 2,
        justifyItems: "center",
        minHeight: 160,
        p: 1,
        width: 1,
        justifyContent: "center",
        boxSizing: "border-box",
      }}
    >
      {children}
    </Box>
  );
}
