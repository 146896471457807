import { ChainPart, ChainPartPriority, ChainPartResult, createChainPart } from "./chainParts.ts";
import store from "../../../global/utils/redux/store.tsx";
import { BlockUserInput } from "@jamezz/react-components";
import { piggyGiftcardAdded } from "../../../global/utils/redux/piggySlice.tsx";
import { GiftcardResponse } from "../../../types/shared/piggy.ts";
import { useLazyFetchGiftcardQuery } from "../../../global/utils/redux/api/piggyApi.ts";
import { useMemo } from "react";

export function usePiggyGiftcardChainPart(isOnHomePage: boolean): ChainPart {
  const [fetchPiggyGiftcard] = useLazyFetchGiftcardQuery();

  return useMemo(() => {
    return createChainPart(
      ChainPartPriority.Medium,
      (data: string) =>
        new Promise<ChainPartResult>((resolve) => {
          if (
            !isOnHomePage &&
            store.getState().global.salesarea.piggy.giftcards.enabled &&
            !BlockUserInput.isUserInputBlocked()
          ) {
            fetchPiggyGiftcard(data)
              .then((response) => {
                if (response.data && !response.error) {
                  store.dispatch(piggyGiftcardAdded((response.data as GiftcardResponse).data.giftcard));
                  resolve("found");
                } else {
                  resolve("not_found");
                }
              })
              .catch(() => {
                resolve("not_found");
              });
          } else {
            resolve("not_found");
          }
        })
    );
  }, [fetchPiggyGiftcard, isOnHomePage]);
}
