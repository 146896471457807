import { Button, Dialog, DialogActions, DialogContent, Divider, Skeleton, Stack, Typography } from "@mui/material";
import store, { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  piggyContactIdentifierDialogClosed,
  piggyContactIdentifierDialogOpened,
} from "../../../global/utils/redux/piggySlice.tsx";
import { useListVouchersQuery } from "../../../global/utils/redux/api/piggyApi.ts";
import Box from "@mui/material/Box";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import PiggyVoucherItem from "./Vouchers/PiggyVoucherItem.tsx";
import _ from "lodash";
import {
  vouchersV2Added,
  vouchersV2NumberOfTimesSum,
  vouchersV2Removed,
  VoucherV2,
} from "../../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import { usePreloadPrepaidData } from "../../utils/piggy/usePiggyLogin.tsx";
import PiggyGiftcardOrderPage from "../../../qr/components/Piggy/Giftcards/PiggyGiftcardOrderPage.tsx";

export default function BavarianVouchersDialog() {
  const isLoggedIn = useAppSelector((state) => state.piggy.contactIdentifierLoggedIn);
  const isOpen = !useAppSelector((state) => state.piggy.piggyContactIdentifierDialogMinimized);

  useEffect(() => {
    if (isLoggedIn) {
      store.dispatch(piggyContactIdentifierDialogOpened());
    }
  }, [isLoggedIn]);

  if (isOpen) {
    return <BavarianVouchers />;
  } else {
    return null;
  }
}

function BavarianVouchers() {
  const isOpen = !useAppSelector((state) => state.piggy.piggyContactIdentifierDialogMinimized);
  const vouchers = useAppSelector((state) => state.vouchersV2.vouchers);

  const piggyVouchersEnabled = useAppSelector((state) => state.global.salesarea.piggy.vouchers.enabled);
  const contactIdentifierScan = useAppSelector((state) => state.piggy.contactIdentifierScan);
  const { data: vouchersList, isFetching } = useListVouchersQuery(
    { contactIdentifier: contactIdentifierScan?.code ?? "", page: 1, status: "ACTIVE" },
    {
      skip: !piggyVouchersEnabled || !contactIdentifierScan,
    }
  );

  const onClose = useCallback(() => {
    store.dispatch(piggyContactIdentifierDialogClosed());
  }, []);

  const applied = useRef<boolean>(false);
  //
  const initVouchersState = useRef<VoucherV2[]>([]);
  //
  useEffect(() => {
    if (isOpen) {
      initVouchersState.current = store.getState().vouchersV2.vouchers;
      applied.current = false;
    }

    if (!isOpen) {
      if (!applied.current) {
        const intersected = _.intersectionBy(
          initVouchersState.current,
          store.getState().vouchersV2.vouchers,
          "voucher.code"
        );
        const removedVouchers = _.differenceBy(initVouchersState.current, intersected, "voucher.code");
        const addedVouchers = _.differenceBy(
          store.getState().vouchersV2.vouchers,
          initVouchersState.current,
          "voucher.code"
        );

        if (addedVouchers.length > 0) {
          store.dispatch(vouchersV2Removed(addedVouchers));
        }
        if (removedVouchers.length > 0) {
          store.dispatch(vouchersV2Added(removedVouchers));
        }

        const diff = findDifferences(initVouchersState.current, store.getState().vouchersV2.vouchers);
        const items = _.chain(diff)
          .filter((a) => a.difference != 0 && a.voucher.voucher.code != null)
          .map((diffObject) => {
            return {
              numberOfTimes: diffObject.difference,
              voucherCode: diffObject.voucher.voucher.code,
            };
          })
          .value();

        if (hasValidVoucherCode(items)) {
          store.dispatch(vouchersV2NumberOfTimesSum(items));
        }
      }
    }
  }, [isOpen]);
  const piggyGiftcardsEnabled = useAppSelector((state) => state.global.salesarea.piggy.giftcards.enabled);
  const data = usePreloadPrepaidData();
  const canApply = useMemo<boolean>(() => {
    return !_.isEqual(initVouchersState.current, vouchers);
  }, [vouchers]);
  const displayTitle = true;
  const prepaidEnabled = useAppSelector((state) => state.global.salesarea.piggy.prepaid.enabled);
  const wrapperSx = { maxWidth: 1 };
  // if ((vouchersList?.data.length == 0 && !isFetching) || !piggyVouchersEnabled) {
  //   return null;
  // }

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth={"xl"}>
      <DialogContent sx={{ overflowY: "auto" }}>
        {piggyGiftcardsEnabled ? <PiggyGiftcardOrderPage color={"#000000"} /> : null}
        <Divider />
        {prepaidEnabled &&
        data?.prepaid_balance.balance_in_cents != null &&
        typeof data.prepaid_balance.balance_in_cents === "number" &&
        data.prepaid_balance.balance_in_cents > 0 ? (
          <>
            <Typography
              sx={{
                fontSize: "2rem",
                px: 2,
                borderRadius: 3,
                fontWeight: 800,
              }}
              data-cy={"piggy-prepaid-balance"}
            >
              <FormattedMessageJamezz
                id={"My balance: {balance}"}
                values={{ balance: data?.prepaid_balance.balance }}
              />
            </Typography>
            <Typography
              sx={{
                fontSize: "2rem",
                px: 2,
                borderRadius: 3,
              }}
              data-cy={"piggy-prepaid-balance"}
            >
              <FormattedMessageJamezz
                id={
                  "At first complete your order, at the end you will be asked whether you want to use your prepaid balance. No worries."
                }
              />
            </Typography>
          </>
        ) : null}
        <Box sx={wrapperSx}>
          {displayTitle ? (
            <Divider
              sx={{
                "&::before": {
                  borderTopWidth: "4px",
                },
                "&::after": {
                  borderTopWidth: "4px",
                },
              }}
            >
              <FormattedMessageJamezz id="Piggy.order-page.vouchers-list.header" />
            </Divider>
          ) : null}
          <Box
            data-cy="piggy-rewards-container"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              pt: 2,
              pb: 1.5,
              gap: 5,
            }}
          >
            {isFetching ? (
              <>
                <Box sx={{ flex: "0 0 auto", display: "flex", justifyContent: "center", px: 2 }}>
                  <Skeleton variant="rectangular">
                    <Box sx={{ height: "160px", width: "320px" }} />
                  </Skeleton>
                </Box>
                <Box sx={{ flex: "0 0 auto", display: "flex", justifyContent: "center", px: 2 }}>
                  <Skeleton variant="rectangular">
                    <Box sx={{ height: "160px", width: "320px" }} />
                  </Skeleton>
                </Box>
                <Box sx={{ flex: "0 0 auto", display: "flex", justifyContent: "center", px: 2 }}>
                  <Skeleton variant="rectangular">
                    <Box sx={{ height: "160px", width: "320px" }} />
                  </Skeleton>
                </Box>
              </>
            ) : vouchersList != null ? (
              <>
                {vouchersList.data.map((piggyVoucher) => (
                  <Box
                    key={piggyVoucher.uuid}
                    sx={{
                      flex: "0 0 auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <PiggyVoucherItem item={piggyVoucher} />
                  </Box>
                ))}
                {vouchersList.data.length === 0 ? (
                  <Typography>
                    <FormattedMessageJamezz id="No vouchers found" />
                  </Typography>
                ) : null}
              </>
            ) : (
              <FormattedMessageJamezz id="Piggy.order-page.vouchers.loading-error" />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {!isFetching ? (
          <Stack sx={{ my: 6, width: 1 }} gap={5} direction={"row"} justifyContent={"center"}>
            <>
              <Button
                variant={"contained"}
                sx={{ maxWidth: 300, width: 1 }}
                onClick={() => {
                  onClose();
                }}
              >
                {typeof vouchersList?.data.length == "number" && vouchersList?.data.length > 0 ? (
                  <FormattedMessageJamezz id={"Cancel"} />
                ) : (
                  <FormattedMessageJamezz id={"Apply"} />
                )}
              </Button>

              {typeof vouchersList?.data.length == "number" && vouchersList?.data.length > 0 ? (
                <Button
                  variant={"contained"}
                  disabled={!canApply}
                  sx={{ maxWidth: 300, width: 1 }}
                  onClick={() => {
                    applied.current = true;
                    onClose();
                  }}
                >
                  <FormattedMessageJamezz id={"Apply"} />
                </Button>
              ) : null}
            </>
          </Stack>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

function hasValidVoucherCode(
  value: { numberOfTimes: number; voucherCode: string | undefined }[]
): value is { numberOfTimes: number; voucherCode: string }[] {
  return value.every((item) => item.voucherCode !== undefined);
}

const findDifferences = (arr1: VoucherV2[], arr2: VoucherV2[]) => {
  // Combine unique codes from both arrays
  const allCodes = _.unionBy(arr1, arr2, "voucher.code");

  return _.map(allCodes, (voucher) => {
    const obj1 = _.find(arr1, {
      voucher: {
        code: voucher.voucher.code,
      },
    }) || { voucher: { number_of_times: 0 } };
    const obj2 = _.find(arr2, {
      voucher: {
        code: voucher.voucher.code,
      },
    }) || { voucher: { number_of_times: 0 } };

    return {
      voucher,
      difference: obj1.voucher.number_of_times - obj2.voucher.number_of_times,
    };
  });
};
