import { TransitionGroup } from "react-transition-group";
import { Collapse, Divider, Typography } from "@mui/material";
import OrderArticleContent from "../../../qr/pages/OrderPage/OrderArticleContent";
import OrderArticleContentKiosk from "../../../kiosk/pages/OrderPage/OrderArticleContent";
import { useMemo, useRef } from "react";
import { useAppSelector } from "../../utils/redux/store";
import { useFetchCustomerRewardsQuery } from "../../utils/redux/api/MWiseApi";
import { MWiseAPIReward } from "../../../types/mwise";
import isKiosk from "../../utils/helpers/isKiosk";
import isQr from "../../utils/helpers/isQr";
import { selectCheckCalculatorDiscounts } from "../../utils/redux/selectors/selectCheckCalculatorDiscounts.ts";

export default function DiscountsCheckCalculator() {
  const checkCalculatorItems = useAppSelector(selectCheckCalculatorDiscounts);
  const checkCalculatorItemsRef = useRef(checkCalculatorItems);
  const checkCalculateSettings = useAppSelector((state) => state.global.salesarea.check_calculate_settings);
  const mwiseEnabled = useAppSelector((state) => state.global.salesarea.mwise.enabled);

  const { data: apiRewards } = useFetchCustomerRewardsQuery(undefined, {
    skip: !mwiseEnabled || !checkCalculateSettings.enabled,
  });

  const apiRewards1 = useMemo(() => {
    const imageMap: { [key: number]: MWiseAPIReward } = {};
    if (apiRewards) {
      apiRewards.forEach((apiReward) => {
        if (apiReward.OracleProduct.discountId) {
          imageMap[apiReward.OracleProduct.discountId] = apiReward;
        }
      });
    }
    return imageMap;
  }, [apiRewards]);

  if (checkCalculatorItems !== undefined) {
    checkCalculatorItemsRef.current = checkCalculatorItems;
  }

  if (!checkCalculateSettings.enabled) {
    return null;
  }
  if (checkCalculatorItemsRef.current === undefined) {
    return null;
  }

  return (
    <TransitionGroup style={{ width: "100%" }}>
      {checkCalculatorItemsRef.current.length > 0 && mwiseEnabled ? (
        <Collapse sx={{ width: 1 }}>
          <Typography className="JS-OrderPage-ExtraatjesTitle">Extraatjes</Typography>
        </Collapse>
      ) : null}

      {checkCalculatorItemsRef.current.map((orderArticle) => {
        const imageUrl = orderArticle.article.customData?.oracleDiscount?.id
          ? apiRewards1[orderArticle.article.customData?.oracleDiscount?.id]?.ImageUrl
          : null;
        if (imageUrl) {
          if (!orderArticle.article.imageMediaUrls) {
            orderArticle.article.imageMediaUrls = [];
          }
          orderArticle.article.imageMediaUrls.unshift({
            original: imageUrl,
            id: 1,
            order_column: 1,
            conversions: { responsive: { url: imageUrl } },
            mime_type: "",
          });
        }
        return (
          <Collapse key={orderArticle.uuid}>
            {isKiosk() ? (
              <OrderArticleContentKiosk key={orderArticle.uuid} orderArticle={orderArticle} />
            ) : (
              <OrderArticleContent key={orderArticle.uuid} orderArticle={orderArticle} />
            )}
          </Collapse>
        );
      })}
      {isQr() && checkCalculatorItemsRef.current.length > 0 ? (
        <Collapse>
          <Divider sx={{ width: 1 }} />
        </Collapse>
      ) : null}
    </TransitionGroup>
  );
}
