import OrderArticle from "../../../../global/utils/models/order/OrderArticle.ts";
import Box from "@mui/material/Box";
import Image from "../../../../global/components/Image.tsx";
import { useMemo } from "react";

export default function DisplayConfiguredMeal({ orderArticle }: { orderArticle: OrderArticle }) {
  const article = orderArticle.article;

  const allOptionItems = useMemo<OrderArticle[]>(() => {
    return orderArticle.orderOptionGroups.reduce((optionItems, orderOptionGroup) => {
      for (const optionItem of orderOptionGroup.orderArticles) {
        if (optionItem.count > 0 && optionItem.article.mealConfiguratorLayer?.conversions?.responsive?.srcset) {
          optionItems.push(optionItem);
        }
      }
      return optionItems;
    }, [] as OrderArticle[]);
  }, [orderArticle]);

  const startingImage = article.mealConfiguratorStart?.conversions?.responsive?.srcset;

  const sorted = useMemo(
    () =>
      [...allOptionItems].sort(
        (a, b) =>
          (b.article.customData?.meal_configurator?.layer_index ?? Number.MIN_VALUE) -
          (a.article.customData?.meal_configurator?.layer_index ?? Number.MIN_VALUE)
      ),
    [allOptionItems]
  );

  return startingImage || allOptionItems.length > 0 ? (
    <Box sx={{ position: "relative", width: 300, height: 300, border: "1px solid grey" }}>
      {startingImage ? (
        <Image
          srcSet={startingImage}
          style={{ position: "absolute", width: "100%", height: "100%", zIndex: 0, objectFit: "contain" }}
        />
      ) : null}
      {sorted.map((optionItem) => {
        return (
          <Image
            key={optionItem.uuid}
            srcSet={optionItem.article.mealConfiguratorLayer?.conversions?.responsive?.srcset}
            style={{ position: "absolute", width: "100%", height: "100%", zIndex: 0, objectFit: "contain" }}
          />
        );
      })}
    </Box>
  ) : null;
}
