import { createSelector } from "@reduxjs/toolkit";
import Campaign, { XPerGroupForYCampaign } from "../../../../global/utils/models/campaigns/Campaign.ts";
import { selectCampaignArticlesMap } from "../../../../global/utils/redux/selectors/selectCampaignArticlesMap.ts";
import _ from "lodash";

export const selectArticleGroupsForCampaign = createSelector(
  [(state, campaign: Campaign) => campaign, selectCampaignArticlesMap],
  (campaign, campaignArticlesMap): { numberOfProducts: number; productIds: number[]; name: string }[] => {
    if (campaign.type == "X_PER_GROUP_FOR_Y") {
      const copy = campaign as XPerGroupForYCampaign;
      return copy.x_per_group_for_y;
    } else if (campaign.type == "X_FOR_Y") {
      return [
        {
          name: "",
          numberOfProducts: campaign.Xs?.[0] ?? 0,
          productIds: _.map(campaignArticlesMap[campaign.id], (value) => Number(value)) ?? [],
        },
      ];
    } else {
      return [];
    }
  }
);
