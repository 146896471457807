import { lighten, Typography, useTheme } from "@mui/material";

export default function PlusOrMinus({
  type,
  onClick,
  maxCount,
}: {
  type: "plus" | "minus";
  onClick: () => void;
  maxCount: number;
}) {
  const theme = useTheme();
  const textColor = theme.palette.getContrastText(theme.palette.primary.light);
  const backgroundColor = lighten(theme.palette.primary.light, 0.2);
  return (
    <Typography
      sx={{
        display: maxCount === 1 ? "none" : undefined,
        backgroundColor: backgroundColor,
        borderRadius: 3,
        fontSize: "1em",
        px: 4,
        py: 1,
        boxShadow: "2px 2px 5px 0px rgba(0,0,0,0.2)",
      }}
      color={textColor}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
    >
      {type === "plus" ? "+" : "-"}
    </Typography>
  );
}
