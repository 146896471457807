import { memo, RefObject, useEffect, useMemo, useRef, useState } from "react";
import ArticleContent from "./ArticleContent/ArticleContent";
import { Collapse } from "@mui/material";
import Articlegroup from "../../../global/utils/models/menu/Articlegroup";
import ArticlegroupBanner from "./ArticlegroupBanner";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../../global/utils/redux/store";
import SearchContent from "./SearchContent";
import { selectArticlegroupsMap } from "../../../global/utils/redux/selectors/selectArticlegroupsMap";
import { selectArticlegroupChildrenMap } from "../../../global/utils/redux/selectors/selectArticlegroupChildrenMap";
import SubArticlegroupTabBar from "../../components/SubArticlegroupsTabBar/SubArticlegroupsTabBar";
import { useArticlegroupFilter } from "../../../global/utils/menu/filters/useMenuFilters";
import Image from "../../../global/components/Image";
import useCategoryItems from "../../../global/utils/menu/useCategoryItems.ts";
import CampaignMenuItem from "./CampaignContent/CampaignMenuItem.tsx";
import { CampaignPricingRule } from "../../../global/utils/models/campaigns/Campaign.ts";

export type MenuOrientation = "HORIZONTAL" | "VERTICAL";

function _ArticlegroupContent({
  articlegroup,
  menuOrientation,
  pixelHeightCartButton,
  depth = 0,
  scrollingRef,
}: {
  articlegroup: Articlegroup;
  menuOrientation: MenuOrientation;
  pixelHeightCartButton?: string;
  depth?: number;
  scrollingRef?: RefObject<HTMLElement>;
}) {
  const { categoryItems } = useCategoryItems(articlegroup);

  const articlegroupFilter = useArticlegroupFilter();
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const useArticlegroupsHierarchically = useAppSelector(
    (state) => state.global.salesarea.custom_data?.appv5_use_articlegroups_hierarchically?.enabled
  );
  const articlegroupChildrenMap = useAppSelector(selectArticlegroupChildrenMap);
  const childArticlegroupIds = useMemo(() => {
    return articlegroupChildrenMap[articlegroup.id] ?? [];
  }, [articlegroupChildrenMap, articlegroup]);

  const childArticlegroups = useMemo(() => {
    return (
      childArticlegroupIds?.map((articlegroupId) => articlegroupsMap[articlegroupId]).filter(articlegroupFilter) ?? []
    );
  }, [childArticlegroupIds, articlegroupsMap, articlegroupFilter]);

  const contentRef = useRef<null | HTMLDivElement>(null);
  const widthPercentage = useMemo(() => {
    return 1 / (articlegroup.numberOfColumns ?? 2);
  }, [articlegroup]);
  const searchValue = useAppSelector((state) => state.global.articleSearchText);
  const isStickyBanner =
    useAppSelector((state) => state.global.salesarea.custom_data?.styling?.kiosk?.stickyBanner) ?? false;

  // useEffect(() => {
  //   /**
  //    * Anayltics ecom data
  //    */
  //   const ecomAnalyticsItems: object[] = [];
  //   filteredArticles.forEach((article: Article) => {
  //     ecomAnalyticsItems.push({
  //       item_id: article.id,
  //       item_name: article.name,
  //       price: article.price,
  //       quantity: 1,
  //     });
  //   });
  //   postEcomAnalyticsEvent("view_item_list", {
  //     item_list_id: articlegroup.id,
  //     item_list_name: articlegroup.name,
  //     items: ecomAnalyticsItems,
  //   });
  // }, [filteredArticles, articlegroup]);

  const [height, setHeight] = useState(0);
  const [heightParent, setHeightParent] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      let element = document.getElementById("JS-ArticlegroupContent-StickyBox:" + (depth - 1));
      if (element) {
        setHeightParent(element.getBoundingClientRect().height);
      }
      element = document.getElementById("JS-ArticlegroupContent-StickyBox:" + depth);
      if (element) {
        setHeight(element.getBoundingClientRect().height);
      }
    }, 500);
  }, []);

  if (categoryItems.length === 0 && childArticlegroups.length === 0 && menuOrientation == "HORIZONTAL") {
    return null;
  }

  return (
    <>
      <Box
        id={"Articlegroup-" + articlegroup.id}
        className={"JS-ArticlegroupContent-Root"}
        ref={contentRef}
        sx={{
          minHeight: menuOrientation === "VERTICAL" ? "calc(100% - 200px)" : undefined,
          display: "flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
          width: 1,
        }}
      >
        <Collapse
          in={!searchValue}
          sx={{ width: 1, ...(isStickyBanner ? { position: "sticky", top: 0, zIndex: 22 + depth } : {}) }}
        >
          {articlegroup.imageBannerMediaUrl ? (
            <Image
              key={articlegroup.imageBannerMediaUrl.id}
              srcSet={articlegroup.imageBannerMediaUrl?.conversions?.responsive?.srcset}
              style={{
                display: "block",
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
              className={"JS-ArticlegroupContent-Image"}
            />
          ) : null}
        </Collapse>
        <Box
          sx={{
            ...(isStickyBanner ? {} : { position: "sticky", top: depth * heightParent - 1 }),
            width: 1,
            zIndex: 20 - depth,
            // backgroundImage: `linear-gradient(to top, ${defaultColor + "0"}, ${defaultColor + "F"})`,
            backgroundColor: "background.default",
            // transition: "all 10000ms ease-in",
          }}
          className={"JS-ArticlegroupContent-StickyBox"}
          id={"JS-ArticlegroupContent-StickyBox:" + depth}
        >
          {!useArticlegroupsHierarchically || childArticlegroups?.length > 0 || categoryItems.length > 0 ? (
            <ArticlegroupBanner
              articlegroup={articlegroup}
              addKioskTopBar={depth === 0 && menuOrientation === "VERTICAL"}
            />
          ) : null}
          {!searchValue && useArticlegroupsHierarchically && childArticlegroups?.length > 0 && depth === 0 ? (
            <>
              <SubArticlegroupTabBar
                articlegroups={childArticlegroups}
                contentRef={scrollingRef}
                heightTopBar={height}
              />
            </>
          ) : null}
        </Box>
        {searchValue ? (
          <SearchContent pixelHeightCartButton={pixelHeightCartButton ?? "200px"} scrollContainerRef={scrollingRef} />
        ) : (
          <>
            {categoryItems.map((categoryItem) => {
              const key = categoryItem?.item_type + ":" + categoryItem?.item.id;
              if (categoryItem?.item_type == "product") {
                return (
                  <Box key={key} sx={{ width: widthPercentage }}>
                    <ArticleContent articlegroup={articlegroup} article={categoryItem.item} />
                  </Box>
                );
              } else if (
                categoryItem?.item_type == "campaign" &&
                (categoryItem.item.type === CampaignPricingRule.X_FOR_Y ||
                  categoryItem.item.type === CampaignPricingRule.X_PER_GROUP_FOR_Y)
              ) {
                return (
                  <CampaignMenuItem
                    key={key}
                    campaign={categoryItem.item}
                    // category={articlegroup}
                    widthPercentage={widthPercentage}
                  />
                );
              } else {
                return null;
              }
            })}
            {useArticlegroupsHierarchically
              ? childArticlegroups?.map((articlegroup) => {
                  return (
                    <ArticlegroupContent
                      key={articlegroup.id}
                      menuOrientation={menuOrientation}
                      articlegroup={articlegroup}
                      depth={(depth ?? 0) + 1}
                    />
                  );
                })
              : null}
          </>
        )}
      </Box>
    </>
  );
}

const ArticlegroupContent = memo(_ArticlegroupContent);
export default ArticlegroupContent;
