import { ChainPart, ChainPartPriority, ChainPartResult, createChainPart } from "./chainParts.ts";
import store from "../../../global/utils/redux/store.tsx";
import { BlockUserInput } from "@jamezz/react-components";
import { piggyRewardsCardScanned } from "../../../global/utils/redux/piggySlice.tsx";
import { useLazyContactByIdentifierQuery } from "../../../global/utils/redux/api/piggyAuthApi.ts";
import { useMemo } from "react";

export function usePiggyRewardChainPart(isOnHomePage: boolean): ChainPart {
  const [fetchContactByUuid] = useLazyContactByIdentifierQuery();

  return useMemo(() => {
    return createChainPart(
      ChainPartPriority.Medium,
      (data: string) =>
        new Promise<ChainPartResult>((resolve) => {
          if (
            !isOnHomePage &&
            (store.getState().global.salesarea.piggy.vouchers.enabled ||
              store.getState().global.salesarea.piggy.loyalty.enabled) &&
            !BlockUserInput.isUserInputBlocked()
          ) {
            const date = new Date();
            fetchContactByUuid({ identifier: data, timestamp: +date, create_if_not_exists: false })
              .then((response) => {
                if (response.data?.data.uuid) {
                  store.dispatch(piggyRewardsCardScanned({ code: data, timestamp: +date }));
                  resolve("found");
                } else {
                  resolve("not_found");
                }
              })
              .catch(() => {
                resolve("not_found");
              });
          } else {
            resolve("not_found");
          }
        })
    );
  }, [fetchContactByUuid, isOnHomePage]);
}
