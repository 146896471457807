import { useListVouchersQuery } from "../../../global/utils/redux/api/piggyApi.ts";
import { transformVoucherV2 } from "../../../global/utils/vouchersV2/api/vouchersApi.tsx";
import PiggyVouchers, { usePotentialVouchers } from "../../components/piggy/Vouchers/PiggyVouchers.tsx";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { VoucherV2 } from "../../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import React, { useEffect, useRef } from "react";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import useVouchers from "../../../global/utils/useVouchers.ts";
import { selectVouchersToBeRedeemedMap } from "../../../global/utils/vouchersV2/selectors/selectVouchersToBeRedeemedMap.ts";

export default function CheckForPotentialVouchersAtCheckoutPage() {
  const { openDialog } = useDialog();
  const piggyVouchersEnabled = useAppSelector((state) => state.global.salesarea.piggy.vouchers.enabled);
  const contactIdentifierScan = useAppSelector((state) => state.piggy.contactIdentifierScan);
  const { data: vouchersList } = useListVouchersQuery(
    { contactIdentifier: contactIdentifierScan?.code ?? "", page: 1, status: "ACTIVE" },
    {
      skip: !piggyVouchersEnabled || !contactIdentifierScan,
    }
  );

  const potentialVouchers = usePotentialVouchers(
    vouchersList?.data?.map((piggyVoucher) => transformVoucherV2(piggyVoucher.jamezzVoucher)) ?? []
  );

  useEffect(() => {
    if (potentialVouchers.length > 0) {
      openDialog({ children: <PotentialVouchersDialog potentialVouchers={potentialVouchers} />, fullWidth: true });
    } else {
    }
  }, [potentialVouchers]);
  return null;
}

export function PotentialVouchersDialog({ potentialVouchers }: { potentialVouchers: VoucherV2[] }) {
  const { closeDialog } = useDialog();
  const ref = useRef();
  const checkVoucherCode = useVouchers({ withLoading: true });

  const vouchersV2 = useAppSelector(selectVouchersToBeRedeemedMap);

  return (
    <>
      <DialogTitle>
        <FormattedMessageJamezz id={"Vouchers?"} />
      </DialogTitle>
      <DialogContent ref={ref}>
        <Stack gap={3}>
          <Typography variant={"subtitle1"}>
            <FormattedMessageJamezz id={"You have some vouchers left:"} />
          </Typography>
          <PiggyVouchers wrapperSx={{ maxWidth: 1 }} displayTitle={false} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} gap={5} sx={{ width: 1 }} justifyContent={"center"}>
          <Button
            onClick={async () => {
              for (const voucher of potentialVouchers) {
                if (voucher.voucher.code) {
                  await checkVoucherCode(
                    voucher.voucher.code,
                    (voucher.voucher.total_redemptions_allowed ?? 0) -
                      (vouchersV2[voucher.voucher.code]?.voucher.number_of_times ?? 0) -
                      (voucher.voucher.times_redeemed ?? 0)
                  );
                }
              }
              closeDialog();
            }}
          >
            <FormattedMessageJamezz id={"Use as many vouchers as possible"} />
          </Button>
          <Button
            onClick={() => {
              closeDialog();
            }}
          >
            <FormattedMessageJamezz id={"Close"} />
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
}
