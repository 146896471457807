import { useIntl } from "react-intl";
import { useMemo } from "react";

export default function useMessagesFromMinAndMaxSelect({
  minSelect,
  maxSelect,
}: {
  minSelect: number;
  maxSelect: number;
}) {
  const intl = useIntl();

  return useMemo<string>(() => {
    const messages = [];

    if (minSelect > 0 && maxSelect > minSelect) {
      messages.push(
        intl.formatMessage(
          { id: "QR.Pick between {min} and {max}" },
          {
            min: minSelect,
            max: maxSelect,
          }
        )
      );
    } else if (maxSelect > 0 && maxSelect != minSelect) {
      messages.push(intl.formatMessage({ id: "QR.Pick maximal {count}" }, { count: maxSelect }));
    } else if (maxSelect > 0) {
      messages.push(intl.formatMessage({ id: "QR.Pick {number} below!" }, { number: maxSelect }));
    }

    return messages.reduce((text, subText, index) => {
      if (index > 0) {
        text += " • ";
      }
      text += subText;
      return text;
    }, "");
  }, [intl, minSelect, maxSelect]);
}
