import { Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import { isOrderOptionGroupValid } from "../../../global/utils/models/order/OrderOptionGroup";
import OptionGroupContent from "./OptionGroups/OptionGroupContent";
import { orderArticlesPushedByUser } from "../../../global/utils/redux/shoppingCartSlice";
import OrderArticle from "../../../global/utils/models/order/OrderArticle";
import store, { useAppDispatch } from "../../../global/utils/redux/store";
import { Box, ButtonBase, Chip, Collapse, Divider, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import useAddOrderArticleToShoppingCart, {
  checkStockAndMaxCount,
} from "../../../global/utils/order/useAddOrderArticleToShoppingCart";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import NextButton from "../../components/NextButton";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import "swiper/css";
import "swiper/css/pagination";
import { OptionTranslationsContext } from "../../../global/utils/translations/useOptionTranslations";
import _ from "lodash";
import useSalesareaType, { SalesareaType } from "../../../global/utils/hooks/useSalesareaType";
import { useMaxAlcoholicItemsCheck } from "../../../global/components/MaxAlcoholicItems/MaxAlcoholicItems";
import { AgeCheckResult } from "../../../global/utils/ageCheck/ageCheckUtils";
import { OrderArticleContext } from "../../../global/utils/contexts/OrderArticleContext.ts";
// @ts-ignore
import scrollIntoView from "scroll-into-view";
import { shouldAutoGotoNextOptionPage } from "./shouldAutoGotoNextOptionPage.tsx";

interface BaseProps {
  orderArticle: OrderArticle;
  modifyingOrderArticle?: OrderArticle;
  compactLayout?: boolean;
  optionOrderGroupsPageIndex: number;
  setOptionOprderGroupsPageIndex: Dispatch<SetStateAction<number>>;
}

interface Props extends BaseProps {
  compactLayout?: false;
  fixedCount?: number;
  onOrderArticleOk?: (orderArticle: OrderArticle) => void;
}

interface PropsCompactLayout extends BaseProps {
  compactLayout: true;
  price: ReactNode;
  fixedCount?: number;
  onOrderArticleOk?: (orderArticle: OrderArticle) => void;
}

export default function OptionOrderGroupsVerticalScrolling(props: Props | PropsCompactLayout) {
  const dispatch = useAppDispatch();
  const context = useContext(OrderArticleContext);
  const setOrderArticle = context?.setOrderArticle;

  const { closeDialog } = useDialog();

  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart();
  const salesareaType = useSalesareaType();
  const checkMaxAlcoholicItems = useMaxAlcoholicItemsCheck();

  const filteredOrderOptionGroups = useMemo(() => {
    return _.uniqBy(props.orderArticle.orderOptionGroups, "id").filter(
      (optionOrderGroup) => !optionOrderGroup.optionGroup.skip
    );
  }, [props.orderArticle.orderOptionGroups]);

  const [showMakeYourChoiceChip, setShowMakeYourChoiceChip] = useState(false);
  const orderArticle = props.orderArticle;

  const validationOrderOptionGroups = useMemo(() => {
    return filteredOrderOptionGroups.map((orderOptionGroup) => {
      return isOrderOptionGroupValid(orderOptionGroup, orderArticle.article);
    });
  }, [filteredOrderOptionGroups, orderArticle.article]);
  const allOrderOptionGroupsAreValid = useMemo(() => {
    return _.every(validationOrderOptionGroups, (a) => a);
  }, [validationOrderOptionGroups]);

  const canOrder = useMemo(() => {
    return orderArticle.article.canOrder && allOrderOptionGroupsAreValid;
  }, [orderArticle, allOrderOptionGroupsAreValid]);

  return (
    <Box sx={{ width: 1, display: "flex", flexDirection: "column", height: 1 }}>
      <Box sx={{ width: 1, textAlign: "center", display: "flex", flexDirection: "column" }}>
        <Collapse in={showMakeYourChoiceChip}>
          <Chip
            sx={{
              backgroundColor: "error.light",
              padding: 3,
              margin: 1,
              fontWeight: 800,
              color: "error.contrastText",
            }}
            label={<FormattedMessageJamezz id={"Please, make a choice!"} />}
          />
        </Collapse>

        {filteredOrderOptionGroups.map((orderOptionGroup, index) => {
          return (
            <OptionTranslationsContext.Provider
              key={orderOptionGroup.id}
              value={orderOptionGroup.optionGroup.translations ?? props.orderArticle.article.translations}
            >
              <OptionGroupContent
                index={index}
                orderArticle={props.orderArticle}
                orderOptionGroup={orderOptionGroup}
                key={orderOptionGroup.id}
                onChange={(orderOptionGroup) => {
                  const copy = _.cloneDeep(props.orderArticle);
                  const index = _.findIndex(copy.orderOptionGroups, (subject) => subject.id == orderOptionGroup.id);
                  copy.orderOptionGroups[index] = orderOptionGroup;
                  setOrderArticle?.(copy);
                  if (shouldAutoGotoNextOptionPage(orderOptionGroup, props.orderArticle)) {
                    const element =
                      document.getElementById("OrderOptionGroup:" + String(index + 1)) ??
                      document.getElementById("OrderOptionGroup:" + String(index));

                    if (element) {
                      scrollIntoView(element, {
                        time: 1000,
                        align: {
                          top: 0,
                          topOffset: 150,
                        },
                        validTarget: () => true,
                      });
                    }
                  }
                }}
              />
            </OptionTranslationsContext.Provider>
          );
        })}
      </Box>

      {salesareaType === SalesareaType.CATALOG ? (
        <Box
          className={"JS-OptionOrderGroups-AddWrapper"}
          sx={{
            display: "flex",
            width: 1,
            justifyContent: "center",
            alignItems: "center",
            marginY: 3,
          }}
        >
          <ButtonBase
            className={"JS-OptionOrderGroups-BackButton"}
            data-cy="page-order-content-btn-back-btn"
            sx={{
              borderRadius: 16,
              textTransform: "uppercase",
              backgroundColor: "primary.light",
              opacity: canOrder ? undefined : 0.6,
              fontSize: 42,
              width: 614,
              padding: 3,
              color: "white",
              fontWeight: 800,
              boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
            }}
            onClick={() => {
              closeDialog();
            }}
          >
            <FormattedMessageJamezz id={"Back"} />
          </ButtonBase>
        </Box>
      ) : (
        <>
          <Divider />
          {store.getState().global.salesarea?.custom_data?.kioskv5?.show_warning_options_belong_to_every_unit ? (
            <Collapse in={Boolean(orderArticle.count > 1 && filteredOrderOptionGroups.length > 0)}>
              <Typography
                sx={{ background: "red", borderRadius: 10, padding: 4, margin: 4, fontSize: "0.8em" }}
                className={"JS-OrderArticleSelectorPage-Warning"}
              >
                <FormattedMessageJamezz
                  id={
                    "Are you sure you want the selected options by {articleName}? If not, add 1 to your order, open {articleName} again and make other choices."
                  }
                  values={{ articleName: orderArticle.article.name }}
                />
              </Typography>
            </Collapse>
          ) : null}
          {props.fixedCount == null ? (
            <Box
              sx={{
                display: "flex",
                width: 1,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
                marginBottom: 3,
                paddingX: 4,
              }}
              className={"JS-OptionOrderGroups-ButtonsWrapper"}
            >
              <ButtonBase
                className={"JS-OptionOrderGroups-MinusButton"}
                sx={{
                  width: 150,
                  padding: 3,
                  backgroundColor: "primary.light",
                  borderRadius: 16,
                  fontSize: 42,
                  color: "white",
                  textTransform: "uppercase",
                  fontWeight: 800,
                  opacity: !(orderArticle.count > (props.modifyingOrderArticle ? 0 : 1)) ? 0.6 : 1,
                  boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
                }}
                disabled={!(orderArticle.count > (props.modifyingOrderArticle ? 0 : 1))}
                onClick={() => {
                  setOrderArticle?.({ ...orderArticle, count: orderArticle.count - 1 });
                }}
              >
                <Remove />
              </ButtonBase>
              <Typography
                className={"JS-OptionOrderGroups-Count"}
                sx={{
                  textAlign: "center",
                  marginX: 3,
                  width: 75,
                }}
              >
                {orderArticle.count}
              </Typography>
              <ButtonBase
                className={"JS-OptionOrderGroups-PlusButton"}
                sx={{
                  width: 150,
                  padding: 3,
                  backgroundColor: "primary.light",
                  borderRadius: 16,
                  fontSize: 42,
                  color: "white",
                  textTransform: "uppercase",
                  fontWeight: 800,
                  boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
                }}
                onClick={async () => {
                  const orderArticles = store.getState().shoppingCart.items;
                  if (!checkStockAndMaxCount({ ...orderArticle, count: orderArticle.count + 1 }, orderArticles)) {
                    return;
                  }

                  const result = await checkMaxAlcoholicItems(
                    { ...orderArticle, count: orderArticle.count + 1 },
                    props.modifyingOrderArticle
                  );

                  if (result == AgeCheckResult.Accepted) {
                    setOrderArticle?.({ ...orderArticle, count: orderArticle.count + 1 });
                  }
                }}
              >
                <Add />
              </ButtonBase>
            </Box>
          ) : null}
          <Box
            className={"JS-OptionOrderGroups-AddWrapper"}
            sx={{
              display: "flex",
              width: 1,
              justifyContent: "center",
              alignItems: "center",
              marginY: 3,
            }}
          ></Box>
        </>
      )}

      <Box
        className={"JS-OptionOrderGroups-NavigationWrapper"}
        sx={{
          width: 1,
          display: "flex",
          pb: 20,
          justifyContent: "space-between",
          marginTop: 1,
          paddingX: 4,
          alignItems: "center",
          pt: 1,
        }}
      >
        {salesareaType !== SalesareaType.CATALOG ? (
          <ButtonBase
            className={"JS-OptionOrderGroups-PreviousButton"}
            sx={{
              minWidth: 375,
              padding: 3,
              borderRadius: 16,
              fontSize: 42,
              color: "primary.contrastText",
              backgroundColor: "primary.light",
              textTransform: "uppercase",
              fontWeight: 800,
              boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
            }}
            onClick={() => {
              closeDialog();
            }}
          >
            <FormattedMessageJamezz id={"Close"} />
          </ButtonBase>
        ) : null}
        {props.compactLayout ? (
          <Typography variant="body2" sx={{ opacity: 0.7, alignSelf: "center", fontStyle: "italic" }}>
            {props.price}
          </Typography>
        ) : null}

        {salesareaType !== SalesareaType.CATALOG ? (
          <Box>
            <NextButton
              sx={{
                opacity: allOrderOptionGroupsAreValid ? 1 : 0.6,
              }}
              isLastOptionGroup={true}
              onClick={async () => {
                if (canOrder) {
                  const result = await checkMaxAlcoholicItems(orderArticle, props.modifyingOrderArticle);

                  if (result == AgeCheckResult.Accepted) {
                    if (props.onOrderArticleOk) {
                      props.onOrderArticleOk(_.cloneDeep(orderArticle));
                    } else if (props.modifyingOrderArticle == null) {
                      try {
                        await addOrderArticleToShoppingCart(orderArticle);
                        closeDialog();
                        if (orderArticle.isUpselled) {
                          closeDialog();
                        }
                      } catch (e) {
                        console.log(e);
                      }
                    } else {
                      closeDialog();
                      dispatch(
                        orderArticlesPushedByUser([
                          {
                            ...props.modifyingOrderArticle,
                            count: -props.modifyingOrderArticle.count,
                          },
                          orderArticle,
                        ])
                      );
                    }
                  }
                } else {
                  setShowMakeYourChoiceChip(true);
                }
              }}
              translationId={props.modifyingOrderArticle == null ? "Add" : "Change"}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
