import { useCallback } from "react";
import OrderArticleSelectorPage from "../pages/OrderArticleSelectorPage/OrderArticleSelectorPage.tsx";
import SlideUpTransition from "../../global/components/SlideUpTransition.tsx";
import { useDialog } from "../../global/utils/dialog/DialogProvider.tsx";
import Article from "../../global/utils/models/menu/Article.ts";
import Articlegroup from "../../global/utils/models/menu/Articlegroup.ts";
import ShowArticleDialogAction from "../../global/utils/automation/actions/ShowArticleDialogAction.ts";
import useRulesForArticle from "../../global/domains/upsells/hooks/useRulesForArticle.ts";
import useOpenUpsellPage, { retrieveValidArticles } from "../../global/domains/upsells/hooks/useOpenUpsellPage.tsx";
import Action from "../../global/utils/automation/actions/Action.ts";
import useMenuFilters from "../../global/utils/menu/filters/useMenuFilters.ts";
import store from "../../global/utils/redux/store.tsx";

export default function useArticleClicked() {
  const { openDialog } = useDialog();
  const getRulesForOrderArticle = useRulesForArticle();
  const openUpsellPage = useOpenUpsellPage();
  const articleFilter = useMenuFilters();

  return useCallback(
    (article: Article, options?: { articlegroup?: Articlegroup }) => {
      const upscaleRule = getRulesForOrderArticle(article, options?.articlegroup).find(
        (rule) =>
          isActionShowArticleDialogAction(rule.action) &&
          rule.action.upsellVariant == "upscale" &&
          rule.conditions.every((condition) => condition.check()) &&
          retrieveValidArticles(articleFilter, rule.action.articleIds).length > 1
      );

      if (
        upscaleRule != null &&
        store.getState().global.salesarea.custom_data.upsell_enabled !== false &&
        isActionShowArticleDialogAction(upscaleRule.action)
      ) {
        const action = upscaleRule.action;
        action.showBottomHideButton = false;
        openUpsellPage(action, article.id);
      } else {
        openDialog({
          children: <OrderArticleSelectorPage article={article} articlegroup={options?.articlegroup} />,
          fullScreen: "almostFullScreen",
          TransitionComponent: SlideUpTransition,
          disabledBackdropClick: true,
        });
      }
    },
    [getRulesForOrderArticle, openDialog, openUpsellPage, articleFilter]
  );
}

function isActionShowArticleDialogAction(action: Action): action is ShowArticleDialogAction {
  return action.discriminator === "ShowArticleDialogAction";
}
