export type ChainPartResult = "found" | "not_found";

export interface ChainPart {
  priority: ChainPartPriority;
  execute: (data: string) => Promise<ChainPartResult>;
}

export enum ChainPartPriority {
  /**
   * # This should only be used for debugging purposes.
   *
   * This is a special priority that will be executed first, even if the other priorities are higher.
   * It is used to debug the chain part execution.
   */
  Debug = 0,
  High = 1,
  Medium = 2,
  Low = 3,
}

export function createChainPart(
  priority: ChainPartPriority,
  callback: (data: string) => Promise<ChainPartResult>
): ChainPart {
  return {
    priority,
    execute: callback,
  };
}

// Function to execute the chain with leveling and parallel execution
export async function executeChain(data: string, chain: ChainPart[]): Promise<ChainPartResult> {
  // Group ChainParts by level
  const levels = new Map<number, ChainPart[]>();

  for (const cp of chain) {
    const level = cp.priority;
    if (!levels.has(level)) {
      levels.set(level, []);
    }
    levels.get(level)!.push(cp);
  }

  // Sort levels in ascending order
  const sortedLevels = Array.from(levels.keys()).sort((a, b) => a - b);
  let foundResult: ChainPartResult | null = null;
  let foundChainPart: ChainPart | null = null;

  for (const level of sortedLevels) {
    console.debug("Processing level: " + level);
    const cpsAtLevel = levels.get(level)!;

    // Execute ChainParts at this level in parallel
    const promises = cpsAtLevel.map((cp) => cp.execute(data).then((result) => ({ cp, result })));

    const results = await Promise.all(promises);

    // Check if any ChainPart returned FoundType
    for (const { cp, result } of results) {
      if (result === "found") {
        foundResult = result;
        foundChainPart = cp;
        break;
      } else if (result === "not_found") {
        /* Do nothing */
      }
    }

    if (foundResult && foundResult === "found") {
      // Stop execution
      break;
    }
  }

  if (foundResult && foundResult === "found" && foundChainPart) {
    return "found";
  } else {
    return "not_found";
  }
}
