import { useCallback } from "react";
import store from "../../../utils/redux/store.tsx";
import { selectArticleTriggers } from "../../../utils/redux/selectors/upsells/selectArticleTriggers.ts";
import { selectMenuTriggers } from "../../../utils/redux/selectors/upsells/selectMenuTriggers.ts";
import Rule from "../../../utils/automation/Rule.ts";
import _ from "lodash";
import Articlegroup from "../../../utils/models/menu/Articlegroup.ts";
import Article from "../../../utils/models/menu/Article.ts";

export default function useRulesForArticle() {
  return useCallback((article: Article, articlegroup?: Articlegroup): Rule[] => {
    const articleRules = selectArticleTriggers(store.getState());
    const menuRules = selectMenuTriggers(store.getState());

    const rules: Rule[] = [];
    if (articlegroup?.id) {
      menuRules[articlegroup?.id]?.forEach((rule: Rule) => {
        rules.push(rule);
      });
    }
    articleRules[article.id]?.forEach((rule: Rule) => {
      rules.push(rule);
    });

    // *------------------------------------------------------------*
    // | THIS IS A WORKAROUND                                       |
    // | The pages are sent to the frontend in the _correct_ order. |
    // | However, because popups are shown using a stack, they      |
    // | appear in reverse order to the customer.                   |
    // | Manually reverting the pages here solves the issue         |
    // *------------------------------------------------------------*
    return _.reverse(_.uniq(rules));
  }, []);
}
