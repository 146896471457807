import store, { useAppSelector } from "../../../global/utils/redux/store";
import { useCallback, useEffect, useState } from "react";
import useInputListener from "../../../global/utils/useInputListener";
import { piggyRewardsCardScanned } from "../../../global/utils/redux/piggySlice";
import { scannerDisabled, scannerEnabled } from "../../../global/utils/redux/globalSlice";
import { debounce } from "lodash";
import { DOUBLE_SCAN_PREVENTION_DEBOUNCE_MS } from "../kioskInputListener/KioskInputListener.tsx";

export enum PiggyScanState {
  UNINITIALIZED,
  LOADING,
  SUCCEEDED,
  FAILED,
}

/**
 * Sets up the scanner for scanning Piggy loyalty cards and manages the scanning state.
 * This utility disables the global scanner on mount, and re-enables it on unmount.
 *
 * @function usePiggyScanner
 * @returns {PiggyScanState} The current state of the Piggy card scan (UNINITIALIZED, LOADING, SUCCEEDED or FAILED).
 *
 * @example
 * const cardScanState = usePiggyScanner();
 *
 * switch (cardScanState) {
 *   case PiggyScanState.SUCCEEDED:
 *     console.log("The card was successfully scanned and logged in.");
 *     break;
 *   case PiggyScanState.FAILED:
 *     console.log("Card scanning failed, or the card is not valid.");
 *     break;
 *   default:
 *     // Handle other states, such as UNINITIALIZED and LOADING.
 * }
 */
export function usePiggyScanner(): { cardScanState: PiggyScanState; turnOn: () => void; turnOff: () => void } {
  const [enabled, _setEnabled] = useState<boolean>(true);
  const contactIdentifierScan = useAppSelector((state) => state.piggy.contactIdentifierScan);
  const isLoggedIn = useAppSelector((state) => state.piggy.contactIdentifierLoggedIn);
  const [cardScanState, setCardScanState] = useState(
    contactIdentifierScan === null ? PiggyScanState.UNINITIALIZED : PiggyScanState.SUCCEEDED
  );

  const processInput = useCallback(
    (code: string) => {
      if (enabled) {
        store.dispatch(piggyRewardsCardScanned({ code, timestamp: +new Date() }));
      }
    },
    [enabled]
  );

  const processDebouncedMethod = debounce(processInput, DOUBLE_SCAN_PREVENTION_DEBOUNCE_MS, { leading: true });

  const onSubmit = useCallback(processDebouncedMethod, [processDebouncedMethod]);

  useInputListener(onSubmit);

  useEffect(() => {
    if (contactIdentifierScan && isLoggedIn == null) {
      setCardScanState(PiggyScanState.LOADING);
    } else if (contactIdentifierScan && isLoggedIn) {
      setCardScanState(PiggyScanState.SUCCEEDED);
      store.dispatch(scannerEnabled());
    } else if (contactIdentifierScan && !isLoggedIn) {
      setCardScanState(PiggyScanState.FAILED);
    }
  }, [setCardScanState, contactIdentifierScan, isLoggedIn]);

  useEffect(() => {
    store.dispatch(scannerDisabled());
    return () => {
      store.dispatch(scannerEnabled());
    };
  }, []);

  const turnOn = useCallback(() => {
    _setEnabled(true);
    store.dispatch(scannerDisabled());
  }, []);
  const turnOff = useCallback(() => {
    _setEnabled(false);
    store.dispatch(scannerEnabled());
  }, []);

  return { cardScanState, turnOn, turnOff };
}
